import { Text } from '@hse24/shared-components';
import { Box } from '@mui/material';
import React from 'react';
import Header from '../../../components/Header/Header';
import t from '../../../constants/translation';
import StreamerOverallPerformance from '../StreamerOverallPerformance/StreamerOverallPerformance';
import StreamerPostsTable from '../StreamerPostsTable/StreamerPostsTable';
import styles from './CreatorPostsOverview.module.scss';
function CreatorPostsOverview() {
  return (
    <>
      <Header title={t.creators.post.Insights} showTitleOnMobile={true} />
      <Box display={'flex'} flexDirection={'column'} className={styles.container} gap={4} mt={4}>
        <Text.B2>{t.creators.post.Overview}</Text.B2>
        <StreamerOverallPerformance />
        <Text.B2>{t.creators.post['Your posts']}</Text.B2>
        <StreamerPostsTable />
      </Box>
    </>
  );
}

export default CreatorPostsOverview;
