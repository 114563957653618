import { ArrowIconLeft, Text } from '@hse24/shared-components';
import { useHistory } from 'react-router-dom';
import styles from './FormHeader.module.scss';

interface FormHeaderProps {
  title: string;
}

function FormHeader({ title }: FormHeaderProps) {
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <div className={styles.exit} onClick={handleGoBack} data-testid="back-button">
        <ArrowIconLeft width="24px" height="24px" />
        <Text.Body className={styles.short_text}>Zurück</Text.Body>
        <Text.Body className={styles.text}>Zurück zur Übersicht</Text.Body>
      </div>
      <div className={styles.header}>
        <Text.J3 className={styles.text}>{title}</Text.J3>
      </div>
    </>
  );
}

export default FormHeader;
