import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../assets';
import Icon from '../../../components/Icon/Icon';
import t from '../../../constants/translation';
import {
  fetchStreamerPostsStats,
  PostsStatsData,
  selectStreamerPostsStats,
} from '../../state/postsSlice';
import OverallPerformanceTile from './OverallPerformanceTile/OverallPerformanceTile';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gridAutoRows: '1fr',
    gridGap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: `repeat(2, calc(50% - ${theme.spacing(1)}))`,
    },
  },
}));

export interface StatTile {
  title: string;
  value: string | number | undefined;
  icon: ReactNode;
  tooltip?: string;
}

const StreamerOverallPerformance = () => {
  const dispatch = useDispatch();
  const streamerPostsStats = useSelector(selectStreamerPostsStats);

  useEffect(() => {
    dispatch(fetchStreamerPostsStats());
  }, [dispatch]);

  const data = streamerPostsStats.data as PostsStatsData | undefined;
  const stats: StatTile[] = [
    {
      title: t.creators.post['Gained followers'],
      value: data == undefined ? '-' : `+${data.totalFollowers}`,
      icon: <Icon icon={Icons.followerGainedMid} width={24} height={24} />,
    },
    {
      title: t.creators.post['All likes'],
      value: data == undefined ? '-' : `${data.totalLikes}`,
      icon: <Icon icon={Icons.heartMid} width={24} height={24} />,
    },
  ];

  const classes = useStyles();

  return (
    <Box className={classes.gridContainer}>
      {stats.map(tile => (
        <OverallPerformanceTile key={tile.title} tile={tile} />
      ))}
    </Box>
  );
};

export default StreamerOverallPerformance;
