import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Role } from '../../auth/role';
import { selectUserIsHelloStreamer } from '../../auth/state/authSlice';
import { featureFlagValue } from '../../config/featureFlagConfig';
import { AvailableFlag } from '../../config/FeatureFlags';
import { showCreateContentDialog } from '../../notifications/state/notificationsSlice';
import routePaths from '../../routes/routePaths';
import {
  bottomNavigationItems,
  desktopNavigationTabs,
  getNavigationItemsForUserRoles,
  NavigationItemType,
  selectActiveNavigationItem,
} from '../Navbar/navigationItems';
import styles from './BottomNavbar.module.scss';

const BottomNavbar = () => {
  // TODO Clean after isContentCreationEnabled FLAG,
  const cleanedDesktopNavigationTabs = {
    ...desktopNavigationTabs,
    [Role.STREAMER_CLASSIC]: desktopNavigationTabs[Role.STREAMER_CLASSIC].map(item =>
      item.name === 'Erstellen' ? { ...item, path: routePaths.creator.addShow } : item
    ),
  };
  const cleanedBottomNavigationItems = {
    ...bottomNavigationItems,
    [Role.STREAMER_CLASSIC]: bottomNavigationItems[Role.STREAMER_CLASSIC].map(item =>
      item.name === 'Erstellen' ? { ...item, path: routePaths.creator.addShow } : item
    ),
  };
  //
  const isHelloStreamer = useSelector(selectUserIsHelloStreamer);
  const isGoodbyeMessageForHelloStreamerEnabled = featureFlagValue(
    AvailableFlag.goodbyeMessageForHelloStreamer
  );
  const availableMenuItems =
    isHelloStreamer && isGoodbyeMessageForHelloStreamerEnabled
      ? cleanedDesktopNavigationTabs[Role.STREAMER_HELLO].filter(item => item.name != 'Erstellen')
      : useSelector(getNavigationItemsForUserRoles(cleanedBottomNavigationItems));

  const navigationTabs =
    isHelloStreamer && isGoodbyeMessageForHelloStreamerEnabled
      ? {
          ...cleanedDesktopNavigationTabs,
          [Role.STREAMER_HELLO]: cleanedDesktopNavigationTabs[Role.STREAMER_HELLO].filter(
            item => item.name != 'Erstellen'
          ),
        }
      : cleanedDesktopNavigationTabs;

  const activeBottomNavigationItemIndexOrFalse = useSelector(
    selectActiveNavigationItem(navigationTabs)
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const onChange = (event: SyntheticEvent, selectedIndex: number) => {
    const item = availableMenuItems[selectedIndex];
    if (item?.type == NavigationItemType.BUTTON) {
      if (item?.name === 'Erstellen') dispatch(showCreateContentDialog());
    } else {
      const path = availableMenuItems[selectedIndex]?.path;
      path && history.location.pathname !== path && history.push(path);
    }
  };

  return (
    <Paper className={styles.container} elevation={3} data-testid="bottom-navbar">
      <BottomNavigation
        value={activeBottomNavigationItemIndexOrFalse}
        onChange={onChange}
        showLabels={true}
      >
        {availableMenuItems.map((item, index) => (
          <BottomNavigationAction
            key={index}
            label={item.name}
            icon={item.icon}
            className={styles.label}
            sx={{
              '& .MuiBottomNavigationAction-label': {
                fontSize: '12px !important',
              },
              '&.MuiBottomNavigationAction-root .MuiAvatar-circular': {
                border: '1.25px solid transparent',
              },
              '&.MuiBottomNavigationAction-root.Mui-selected .MuiAvatar-circular': {
                borderColor: '#081F3E',
              },
            }}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNavbar;
