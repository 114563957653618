import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createMiddleware } from 'redux-beacon';
import createSagaMiddleware from 'redux-saga';
import academyReducer from '../academy/state/academySlice';
import agenciesReducer from '../agencies/state/agenciesSlice';
import appReducer from '../app/state/appSlice';
import authReducer from '../auth/state/authSlice';
import basketReducer from '../basket/state/basketSlice';
import Config from '../config/config';
import creatorsReducer from '../creators/state/creatorsSlice';
import reduxBeaconMiddleware, {
  eventsMap,
} from '../middlewares/reduxBeaconMiddleware/reduxBeaconMiddleware';
import notificationsReducer from '../notifications/state/notificationsSlice';
import amasReducer from '../onDemandInteractions/amas/state/amasSlice';
import paymentsReducer from '../payments/state/paymentsSlice';
import postsReducer from '../posts/state/postsSlice';
import productsReducer from '../products/state/productsSlice';
import showsReducer from '../shows/state/showsSlice';
import streamChatReducer from '../streamChat/state/streamChatSlice';
import surveysReducer from '../surveys/state/surveysSlice';
import vouchersReducer from '../vouchers/state/vouchersSlice';
import { rootSaga } from './rootSaga';

export const browserHistory = createBrowserHistory({
  basename: Config.env.baseRoute,
});

const sagaMiddleware = createSagaMiddleware();
const gtm = GoogleTagManager();

export const store = configureStore({
  reducer: {
    app: appReducer,
    agencies: agenciesReducer,
    auth: authReducer,
    creators: creatorsReducer,
    notifications: notificationsReducer,
    shows: showsReducer,
    payments: paymentsReducer,
    products: productsReducer,
    amas: amasReducer,
    router: connectRouter<unknown>(browserHistory),
    posts: postsReducer,
    academy: academyReducer,
    surveys: surveysReducer,
    streamChat: streamChatReducer,
    basket: basketReducer,
    vouchers: vouchersReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ thunk: false }).concat([
      sagaMiddleware,
      routerMiddleware(browserHistory),
      reduxBeaconMiddleware(),
      createMiddleware(eventsMap, gtm),
    ]),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const selectCurrentLocation = (state: RootState) => state.router.location;
