import { useEmblaCarousel } from 'embla-carousel/react';
import React, { FC, ReactNode } from 'react';
import styles from './ContentCarousel.module.scss';

interface Props {
  children?: ReactNode;
}

const ContentCarousel: FC<Props> = ({ children }) => {
  const [emblaRef] = useEmblaCarousel({ loop: false, align: 'start', containScroll: 'keepSnaps' });

  return (
    <div className={styles.contentSlider}>
      <div className={styles.emblaViewport} ref={emblaRef}>
        <div className={styles.slidesWrapper}>
          {React.Children.map(children, (child, index) => (
            <div className={styles.slide} key={index}>
              {child}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContentCarousel;
