import { useHistory } from 'react-router-dom';
import followerGainedImage from '../../../assets/follower-gained-image.png';
import heartImage from '../../../assets/heart-image.png';
import Header from '../../../components/Header/Header';
import { AvailableFlag, Flag } from '../../../config/FeatureFlags';
import t from '../../../constants/translation';
import routePaths from '../../../routes/routePaths';
import CarouselSlide from './ContentSlider/CarouselSlide/CarouselSlide';
import ContentCarousel from './ContentSlider/ContentCarousel';
import CreationOverview from './CreationOverview/CreationOverview';
import styles from './CreatorContentComponent.module.scss';

const CreatorContentComponent = () => {
  const history = useHistory();

  const slidesData = [
    {
      backgroundImage: followerGainedImage,
      title: t.creators.post['Increase your reach'],
      description: t.creators.post['Gain new followers for your topics and products'],
      buttonText: t.creators.post['Create video post'],
      onButtonClick: () => history.push(routePaths.creator.addPost),
    },
    {
      backgroundImage: heartImage,
      title: t.creators.post['Inspire your fans live!'],
      description:
        t.creators.post['Interact with your fans in real time and strengthen your community'],
      buttonText: t.creators.post['Schedule a live stream'],
      onButtonClick: () => history.push(routePaths.creator.addShow),
    },
  ];

  return (
    <Flag flag={AvailableFlag.isContentCreationEnabled}>
      <Header
        title={t.creators.Create}
        showTitleOnMobile={true}
        titleTestId={'creator-content-title'}
        className={styles.creatorHeader}
      />
      <div data-testid="creator-content-container" className={styles.creator_content_container}>
        <ContentCarousel>
          {slidesData.map((slide, index) => (
            <CarouselSlide
              key={index}
              backgroundImage={slide.backgroundImage}
              title={slide.title}
              description={slide.description}
              buttonText={slide.buttonText}
              onButtonClick={slide.onButtonClick}
            />
          ))}
        </ContentCarousel>
        <CreationOverview />
      </div>
    </Flag>
  );
};

export default CreatorContentComponent;
