import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import {
  clearProductsSelection,
  selectProductSelection,
} from '../../../products/state/productsSlice';
import theme from '../../../theme/theme.module.scss';
import { changeWhiteSpacesToHTMLFormat } from '../../../utils/formatters/htmlFormatter/htmlFormatter';
import AdminCreateEditPostForm from '../../components/AdminCreateEditPostForm/AdminCreateEditPostForm';
import { AdminStreamPostData } from '../../model/post';
import { clearPostCreationData, createNewPostByAdmin } from '../../state/postsSlice';

// Temporary code - to be adjusted after SC-15668 and SC-15670 are done
const AdminCreatePostView = () => {
  const dispatch = useDispatch();
  const { selectedProducts } = useSelector(selectProductSelection);

  useEffect(() => {
    dispatch(clearProductsSelection());
    dispatch(clearPostCreationData());
  }, []);

  const onPublishPost = (adminStreamPostData: AdminStreamPostData) => {
    if (adminStreamPostData.medias.length > 0 && adminStreamPostData.caption?.trim()) {
      dispatch(
        createNewPostByAdmin({
          ...adminStreamPostData,
          baseProductsNo: selectedProducts.map(p => p.baseProductNo),
          caption: changeWhiteSpacesToHTMLFormat(adminStreamPostData.caption),
        })
      );
    }
  };

  return <AdminCreateEditPostForm isCreate submitCallback={onPublishPost} />;
};

function AdminCreatePostPage() {
  return (
    <PageContainer
      backgroundColor={theme.whiteLilac}
      pageToRender={<AdminCreatePostView />}
      fullHeight
    />
  );
}

export default AdminCreatePostPage;
