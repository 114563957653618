import { Button, EButtonSize, EButtonType } from '@hse24/shared-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, IconButton } from '@mui/material';
import cx from 'classnames';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import Icons from '../../../assets';
import Icon from '../../../components/Icon/Icon';
import Config from '../../../config/config';
import t from '../../../constants/translation';
import ProductSlider from '../../../products/components/ProductSlider/ProductSlider';
import routePaths from '../../../routes/routePaths';
import {
  formatDate,
  formatTime,
  getDayOfTheWeek,
} from '../../../utils/formatters/dateFormatter/dateFormatter';
import { UpcomingShowWithCreator } from '../../api/showsRequestResponse';
import { ShowStatus, StreamedBy } from '../../model/shows';
import { changeShowVisibility, deleteShowByAdmin } from '../../state/showsSlice';
import DeletionConfirmationDialog from '../ShowDetails/DeletionConfirmationDialog/DeletionConfirmationDialog';
import UnpublishedConfirmationDialog from '../ShowDetails/UnpublishedConfirmationDialog/UnpublishedConfirmationDialog';
import styles from './AdminPlannedShowTile.module.scss';
import ShowMenuOptions from './ShowMenuOptions/ShowMenuOptions';
import ShowStatusDropDown from './ShowStatusDropDown/ShowStatusDropDown';

interface AdminPlannedShowTileProps {
  show: UpcomingShowWithCreator;
}

const AdminPlannedShowTile = ({ show }: AdminPlannedShowTileProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [deleteModelOpen, setDeleteModelOpen] = useState<boolean>(false);
  const [unpublishModelOpen, setUnpublishModelOpen] = useState<boolean>(false);

  const navigateToShowDetails = () => {
    const path = generatePath(routePaths.hseEmployee.editShow, { showId: show.id });
    history.push(path);
  };

  const navigateToDuplicateShow = () => {
    const path = generatePath(routePaths.hseEmployee.duplicateShow, { showId: show.id });
    history.push(path);
  };

  function onMenuIconClick(e: React.MouseEvent<HTMLButtonElement>) {
    setAnchor(e.currentTarget);
  }

  const onMenuClose = () => {
    setAnchor(null);
  };

  const onDeleteClicked = () => {
    setAnchor(null);
    setDeleteModelOpen(true);
  };

  const onStreamerInfoClick = () => {
    if (Config.env.dummyCreatorId == show.creator.id) {
      navigateToShowDetails();
    } else {
      const path = generatePath(routePaths.hseEmployee.creatorDetails, {
        streamerId: show.creator.id,
      });
      history.push(path);
    }
  };

  const handleDelete = () => {
    dispatch(deleteShowByAdmin({ showId: show.id }));
  };

  const handleUnpublish = (isPublished = false) => {
    dispatch(
      changeShowVisibility({ showId: show.id, isPublished, updateUpcomingShowsOverview: true })
    );
  };

  const handleCloseDeleteDialog = () => {
    setDeleteModelOpen(false);
  };

  const handleCloseUnpublishDialog = () => {
    setUnpublishModelOpen(false);
  };

  const isShowReadyToBePublished = Boolean(
    Config.env.dummyCreatorId != show.creator.id &&
      show.title &&
      show.products.length &&
      show.previewImageUrl
  );

  return (
    <div className={styles.container} data-testid="admin-planned-show-tile">
      {show.status == ShowStatus.UNPUBLISHED && <div className={styles.overlay} />}
      <DeletionConfirmationDialog
        handleClose={handleCloseDeleteDialog}
        openDialog={deleteModelOpen}
        handleDelete={handleDelete}
      />
      <UnpublishedConfirmationDialog
        handleClose={handleCloseUnpublishDialog}
        openDialog={unpublishModelOpen}
        handleUnpublish={handleUnpublish}
      />
      <ShowMenuOptions
        show={show}
        anchorEl={anchor}
        isOpen={!!anchor}
        handleClose={onMenuClose}
        handleDelete={onDeleteClicked}
        testId={'show-menu-options'}
      />
      <div className={styles.images_container}>
        {show.previewImageUrl ? (
          <>
            <img
              src={`${show.previewImageUrl}?imwidth=160`}
              alt={show.title}
              className={styles.show_image}
              data-testid="streamer-preview-image"
            />
            <div className={styles.streamer_image_overlay} />
          </>
        ) : (
          <div className={styles.show_image_placeholder}>
            <div className={styles.show_image_placeholder_wrapper}>
              <Icon icon={Icons.imageBackgroundIcon} className={styles.icon} />
              <div onClick={navigateToShowDetails} className={styles.placeholder}>
                {t.common['Upload image']}
              </div>
            </div>
          </div>
        )}
        <div className={styles.streamer_info}>
          <Avatar
            src={`${show.creator.profileImageUrl}`}
            alt={show.creator.name}
            className={styles.streamer_avatar}
            onClick={onStreamerInfoClick}
            data-testid="streamer-image-avatar"
          >
            <PersonIcon fontSize="small" />
          </Avatar>
          <span
            className={cx(styles.streamer_name, {
              [styles.streamer_name_dummy]: Config.env.dummyCreatorId == show.creator.id,
              [styles.streamer_name_no_preview]: !show.previewImageUrl,
            })}
            data-testid={'streamer-name'}
            onClick={onStreamerInfoClick}
          >
            {show.creator.name}
          </span>
        </div>
      </div>
      <div className={styles.show_details}>
        <div className={styles.show_details_left}>
          <div>
            <div className={styles.date} data-testid="stream-date">
              <span>{getDayOfTheWeek(show.scheduledAt)}</span>&nbsp;|&nbsp;
              <span>{formatDate(show.scheduledAt)}</span>&nbsp;|&nbsp;
              <span>{formatTime(show.scheduledAt)}</span>
            </div>
            <div className={styles.sales_topic}>
              {show.streamedBy === StreamedBy.HSE ? show.salesTopic : t.common['Creator Stream']}
            </div>
            <div
              onClick={!show.title ? navigateToShowDetails : undefined}
              className={cx(styles.title, { [styles.title_placeholder]: !show.title })}
            >
              {show.title ? show.title : t.common['Enter a title']}
            </div>
          </div>
          {show.products.length ? (
            <ProductSlider
              className={styles.product_slider}
              productsPreview={show.products}
              forAdmin={true}
            />
          ) : (
            <div onClick={navigateToShowDetails} className={styles.products_placeholder}>
              <div className={styles.placeholder}>{t.creators.post['Add products']}</div>
            </div>
          )}
        </div>
        <div className={styles.show_details_right}>
          <div className={styles.show_icon_buttons}>
            {show.status == ShowStatus.DRAFT && (
              <IconButton
                className={styles.icon_button}
                onClick={navigateToShowDetails}
                data-testid="edit-icon-button"
              >
                <Icon icon={Icons.pencilIcon} style={{ color: 'black' }} dataTestId="pencil-icon" />
              </IconButton>
            )}
            <IconButton
              className={styles.icon_button}
              onClick={navigateToDuplicateShow}
              data-testid="duplicate-icon-button"
            >
              <Icon icon={Icons.copyIcon} style={{ color: 'black' }} dataTestId="copy-icon" />
            </IconButton>
            <IconButton
              className={styles.icon_button}
              onClick={onMenuIconClick}
              data-testid="more-icon-button"
            >
              <Icon icon={Icons.moreIcon} style={{ color: 'black' }} dataTestId="more-icon" />
            </IconButton>
            <ShowStatusDropDown
              showId={show.id}
              showStatus={show.status}
              isShowReadyToBePublished={isShowReadyToBePublished}
            />
          </div>
          <div className={styles.show_info_button_wrapper} onClick={navigateToShowDetails}>
            <Button
              type={EButtonType.NONE}
              className={styles.show_info_button}
              size={EButtonSize.SMALL}
            >
              {t.admins.upcomingShowsOverview['View stream details']}
            </Button>
            <ArrowForwardIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPlannedShowTile;
