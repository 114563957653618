import { Button, EButtonText, EButtonType } from '@hse24/shared-components';
import { GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import Icons from '../../../../assets';
import Icon from '../../../../components/Icon/Icon';
import t from '../../../../constants/translation';
import styles from './CustomToolbar.module.scss';

interface CSVOptions {
  utf8WithBom?: boolean;
  delimiter?: string;
  getRowsToExport?: () => void;
  disableToolbarButton?: boolean;
}
interface CustomToolbarProps {
  onReset: () => void;
  showResetButton: boolean;
  csvOptions?: CSVOptions;
  quickFilterPlaceholder?: string;
  filterMode?: string;
  value?: string;
}

const CustomToolbar: React.FC<CustomToolbarProps> = ({
  onReset,
  showResetButton,
  csvOptions,
  quickFilterPlaceholder,
  filterMode,
  value,
}) => {
  const [quickFilterField, setQuickFilterField] = useState<string | undefined>(
    value && value !== '' ? value : undefined
  );
  useEffect(() => {
    // After the first render, reset 'quickFilterField' to undefined because if we don't it will keep the input value the same
    setQuickFilterField(undefined);

    const inputElement = document.getElementById('quick-filter-input') as HTMLInputElement;
    if (inputElement && inputElement.value.trim() !== '') {
      inputElement.focus();
    }
  }, []);

  return (
    <GridToolbarContainer>
      <div className={styles.toolbar_container}>
        <div className={styles.header_wrapper}>
          <GridToolbar
            csvOptions={csvOptions}
            printOptions={{ disableToolbarButton: true }}
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          />
          {showResetButton && (
            <Button
              onClick={onReset}
              textAlign={EButtonText.CENTER}
              type={EButtonType.LINK}
              className={styles.reset_button}
            >
              <Icon className={styles.icon} dataTestId="refresh-icon" icon={Icons.refreshIcon} />
              {t.common['Reset table']}
            </Button>
          )}
        </div>
        <GridToolbarQuickFilter
          id="quick-filter-input"
          className={styles.quick_filter}
          debounceMs={800}
          placeholder={quickFilterPlaceholder ?? 'Search...'}
          filterMode={filterMode}
          value={quickFilterField}
        />
      </div>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
