import { GridColDef } from '@mui/x-data-grid';
import { FutureDateFilters } from '../components/DateFilter/DateFilterInFuture/DateFilterInFuture';
import { PastDateFilters } from '../components/DateFilter/DateFilterInPast/DateFilterInPast';
import { ShowsQueryParams } from '../shows/api/showsRequestResponse';
import { QueryData } from '../shows/state/showsSlice';

export interface CsvRow {
  [key: string]: string | number | boolean | undefined;
}

export interface CsvHeader {
  headerName: string;
  field: string;
}

export const addPathQuery = (path: string, query: Record<string, string>) => {
  return path + '?' + new URLSearchParams(query).toString();
};

export const secondsIntoMinutes = (seconds: number) => Math.round(seconds / 60) || 1;

export const secondsToMmSs = (seconds: number): string => {
  const totalSeconds = Math.floor(seconds);
  const minutes = Math.floor(totalSeconds / 60);
  const remainingSeconds = totalSeconds % 60;

  const paddedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${minutes}:${paddedSeconds}`;
};

// 86400 1 day
export const setCookie = (
  name: string,
  value: string,
  secure = true,
  maxAge = 86400,
  path = '/'
) => {
  if (!document.cookie.includes(`${name}=`)) {
    const cookie = `${name}=${value}; max-age=${maxAge}; path =${path}`;
    document.cookie = secure ? cookie.concat('; secure') : cookie;
  }
};

export const downloadCSV = (headers: CsvHeader[], data: CsvRow[], filename: string) => {
  if (data.length === 0) {
    console.error('Data is empty');
    return;
  }

  const rows = data.map(row =>
    headers
      .map(header => {
        const field = row[header.field];
        if (field !== undefined) {
          if (field.toString().includes(';')) {
            return `"${field}"`;
          }
          return field;
        }
        return '';
      })
      .join(';')
  );

  const csv = [headers.map(header => header.headerName).join(';'), ...rows].join('\n');

  const blob = new Blob([csv], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

export const mapToShowQueryParams = (
  queryData: QueryData<PastDateFilters | FutureDateFilters>
): ShowsQueryParams => {
  if (queryData.dateFrom && queryData.dateTo) {
    return {
      dateFrom: queryData.dateFrom,
      dateTo: queryData.dateTo,
      sortField: queryData.sortField,
      searchTerm: queryData.searchTerm,
      sortOrder: queryData.sortOrder,
      page: queryData.page,
      pageSize: queryData.pageSize,
      audience: queryData.audience,
      streamedBy: queryData.streamedBy,
      excludeEarlyBirds: queryData.excludeEarlyBirds,
      onlyDraft: queryData.onlyDraft,
    };
  }
  throw new Error('starting date and ending date must be set');
};

export const extractGridTableColumnsToExportFromLocalStorage = (
  localStorageKey: string,
  columns: GridColDef[]
): CsvHeader[] => {
  const mapDefaultColumnsToExport = (): CsvHeader[] =>
    columns.map(column => {
      return {
        headerName: column.headerName!,
        field: column.field,
      };
    });

  const columnVisibilityModel = localStorage.getItem(localStorageKey);
  if (columnVisibilityModel) {
    const selectColumns: Record<string, boolean> = JSON.parse(columnVisibilityModel);
    const columnsToDisplay = Object.entries(selectColumns)
      .filter(([, value]) => value)
      .map(([key]) => key);
    const columnsToExport: CsvHeader[] = [];
    columnsToDisplay.map(selectedColumn => {
      const searchedColumn = columns.find(column => column.field === selectedColumn);
      if (searchedColumn) {
        columnsToExport.push({
          headerName: searchedColumn.headerName!,
          field: searchedColumn.field,
        });
      }
    });
    if (columnsToExport.length === 0) {
      return mapDefaultColumnsToExport();
    }
    return columnsToExport;
  }
  return mapDefaultColumnsToExport();
};
