import { camelizeKeys } from 'humps';
import authClient from '../../auth/authClient';
import Config from '../../config/config';
import { Voucher } from '../model/voucher';

const defaultBaseUrl = `${Config.env.socialLifeCommerceServiceBaseUrl}`;

const vouchersClient = (baseUrl: string) => authClient.createClient(`${baseUrl}/vouchers`);

const fetchSuggestedVouchers = async () => {
  const response = await vouchersClient(defaultBaseUrl).get<Voucher[]>('/suggested-vouchers');
  return camelizeKeys(response.data) as Voucher[];
};

export const vouchersApi = {
  fetchSuggestedVouchers,
};
