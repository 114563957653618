import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useMemo } from 'react';
import ResponsiveDetailsRow from '../../../../../components/DetailsRow/ResponsiveDetailsRow';
import Config from '../../../../../config/config';
import t from '../../../../../constants/translation';
import {
  formatVoucherValidityDate,
  formatVoucherValueByType,
} from '../../../../../utils/formatters/voucherFormatter/voucherFormatter';
import {
  Voucher,
  VoucherRestriction,
  VoucherRestrictionMode,
  VoucherRestrictionType,
} from '../../../../../vouchers/model/voucher';
import RestrictionCard from './RestrictionCard/RestrictionCard';
import styles from './VoucherRestrictions.module.scss';

interface Props {
  voucher?: Voucher;
  isLoading: boolean;
}

const VoucherRestrictions = ({ voucher, isLoading }: Props) => {
  if (!voucher || isLoading)
    return (
      <>
        <Box mt={2}>
          <ResponsiveDetailsRow
            label={t.admins.Vouchers.Discount}
            value={
              formatVoucherValueByType(voucher) +
              ' ' +
              (voucher?.restrictions.length === 0 ? t.admins.Vouchers['on all'] : '')
            }
            isLoading={isLoading}
          />
        </Box>
        <Box mt={2}>
          <ResponsiveDetailsRow
            label={t.admins.Vouchers['Validity period']}
            value={formatVoucherValidityDate(voucher)}
            isLoading={isLoading}
          />
        </Box>
      </>
    );

  const restrictions = voucher.restrictions.filter(
    restriction =>
      !(
        restriction.type === VoucherRestrictionType.PRODUCT &&
        restriction.mode === VoucherRestrictionMode.EXCLUDE &&
        restriction.baseProductNo &&
        Config.env.vouchers.excludedBooksSkus.includes(restriction.baseProductNo)
      )
  );

  const restrictionsGroupedByMode = useMemo(() => {
    return restrictions.reduce((acc, restriction) => {
      const key = restriction.mode;
      acc.set(key, [...(acc.get(key) ?? []), restriction]);
      return acc;
    }, new Map<VoucherRestrictionMode, VoucherRestriction[]>());
  }, [restrictions]);

  return (
    <>
      <>
        <Box mt={2}>
          <ResponsiveDetailsRow
            updateStyle={{ fontSize: '16px', fontWeight: 400 }}
            label={t.admins.Vouchers.Discount}
            value={
              formatVoucherValueByType(voucher) +
              ' ' +
              (restrictionsGroupedByMode.has(VoucherRestrictionMode.INCLUDE)
                ? t.admins.Vouchers.on
                : t.admins.Vouchers['on everything except'])
            }
            last={true}
          />
        </Box>
        {restrictionsGroupedByMode.has(VoucherRestrictionMode.INCLUDE) && (
          <RestrictionCard
            restrictions={restrictionsGroupedByMode.get(VoucherRestrictionMode.INCLUDE)}
          ></RestrictionCard>
        )}
      </>

      {restrictionsGroupedByMode.has(VoucherRestrictionMode.INCLUDE) && (
        <Typography
          sx={{
            color: '#D32F2F',
          }}
          mt={2}
        >
          {t.admins.Vouchers['except on']}
        </Typography>
      )}
      <RestrictionCard
        restrictions={restrictionsGroupedByMode.get(VoucherRestrictionMode.EXCLUDE)}
        displayExcludedBooksBanner={true}
      ></RestrictionCard>
      <Box className={styles.divider} />
      <Box mt={2}>
        <ResponsiveDetailsRow
          label={t.admins.Vouchers['Validity period']}
          value={formatVoucherValidityDate(voucher)}
          isLoading={isLoading}
        />
      </Box>
    </>
  );
};
export default VoucherRestrictions;
