import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import CreatorContentContainer from '../../components/CreatorContentContainer/CreatorContentContainer';
import styles from './CreatorContentManagmentPage.module.scss';

const CreatorContentManagmentPage = () => {
  const content = () => (
    <div>
      <CreatorContentContainer />
    </div>
  );

  return (
    <PageContainer
      pageToRender={content()}
      backgroundColor={theme.whiteLilac}
      fullHeight
      spacing={0}
      className={styles.creator_content_managment_container}
    />
  );
};

export default CreatorContentManagmentPage;
