import { Chip, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useMemo } from 'react';
import t from '../../../../../../constants/translation';
import {
  VoucherRestriction,
  VoucherRestrictionType,
} from '../../../../../../vouchers/model/voucher';
import styles from './RestrictionCard.module.scss';

interface Props {
  restrictions?: VoucherRestriction[];
  displayExcludedBooksBanner?: boolean;
}
const RestrictionCard = ({ restrictions, displayExcludedBooksBanner }: Props) => {
  const restrictionsGroupedByType = useMemo(() => {
    if (restrictions != undefined)
      return restrictions.reduce((acc, restriction) => {
        const key = restriction.type;
        acc.set(key, [...(acc.get(key) ?? []), restriction]);
        return acc;
      }, new Map<VoucherRestrictionType, VoucherRestriction[]>());
  }, [restrictions]);

  if (!restrictionsGroupedByType && !displayExcludedBooksBanner) {
    return null;
  }

  const productRestriction = restrictionsGroupedByType?.get(VoucherRestrictionType.PRODUCT);
  const brandRestriction = restrictionsGroupedByType?.get(VoucherRestrictionType.BRAND);
  const categoryRestriction = restrictionsGroupedByType?.get(VoucherRestrictionType.CATEGORY);
  const productPriceLabelRestriction = restrictionsGroupedByType?.get(
    VoucherRestrictionType.PRODUCT_PRICE_LABEL
  );

  return (
    <>
      {(displayExcludedBooksBanner || productRestriction?.length) && (
        <Box display="flex" flexDirection="row" alignContent="flex-start" mt={2}>
          <Typography>{t.admins.Vouchers.Product}</Typography>
          <Typography marginX={0.5}>-</Typography>
          <Box className={styles.inline_items} gap={1}>
            {displayExcludedBooksBanner && (
              <Chip
                variant="filled"
                label={t.admins.Vouchers['Books are excluded from the voucher']}
                className={styles.product_chip}
              />
            )}
            {productRestriction &&
              productRestriction.map(restriction => (
                <Tooltip
                  key={restriction.baseProductNo}
                  title={restriction.name}
                  placement={'bottom-start'}
                >
                  <Chip
                    variant="filled"
                    label={restriction.baseProductNo}
                    className={styles.product_chip}
                  />
                </Tooltip>
              ))}
          </Box>
        </Box>
      )}
      {brandRestriction?.length && (
        <Box mt={2} className={styles.inline_items}>
          <Typography>{t.admins.Vouchers.Brand}</Typography>
          <Typography marginX={0.5}>-</Typography>
          {brandRestriction.map(restriction => restriction.name.toUpperCase()).join(', ')}
        </Box>
      )}
      {categoryRestriction?.length && (
        <Box mt={2} className={styles.inline_items}>
          <Typography>{t.admins.Vouchers.Category}</Typography>
          <Typography marginX={0.5}>-</Typography>
          {categoryRestriction.map(restriction => restriction.name.toUpperCase()).join(', ')}
        </Box>
      )}
      {productPriceLabelRestriction?.length && (
        <Box mt={2} className={styles.inline_items}>
          <Typography>{t.admins.Vouchers['Price label']}</Typography>
          <Typography marginX={0.5}>-</Typography>
          {productPriceLabelRestriction
            .map(restriction => restriction.name.toUpperCase())
            .join(', ')}
        </Box>
      )}
    </>
  );
};
export default RestrictionCard;
