import { FloorSale, PageableResponse } from '../../common/types';
import { Creator } from '../../shows/model/shows';
import { Post, PostStatus } from '../model/post';
import { MediaRequest } from '../state/postsSlice';

export interface PostPreSignedUrlRequest {
  fileExtension: string;
  mediaType?: MediaType;
  fileName?: string;
}

export interface AdminPostPreSignedUrlsRequest {
  attachments: PostPreSignedUrlRequest[];
  postId?: string;
  creatorId: string;
}
export interface UploadPostMediaToS3Request {
  fileUrl: string | null;
  preSignedUrl?: string;
}

export interface AdminDeleteS3ObjectsRequest {
  keys: string[];
  creatorId: string;
}

export interface PostPreSignedUrlResponse {
  preSignedUrl: string;
  key: string;
}
export interface PostPreSignedUrlsResponse {
  keys: PostPreSignedUrlResponse[];
  postId: string;
}

export interface PostCreationRequest {
  postId: string;
  caption: string;
  baseProductsNo: string[];
  fileExtension: string;
  s3Key: string;
  mediaType?: MediaType;
  scheduleAt?: string;
}

export interface AdminPostCreationRequest
  extends Omit<PostCreationRequest, 'fileExtension' | 's3Key' | 'mediaType'> {
  medias: MediaRequest[];
  salesTheme?: string;
  creatorId: string;
}

export enum MediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export interface PostUpdatePayload {
  postId: string;
  request: PostUpdateRequest;
}

export interface PostUpdateRequest {
  caption: string;
  baseProductsNo: string[];
  scheduleAt?: string;
}

export interface AdminPostUpdateRequest extends PostUpdateRequest {
  medias: MediaRequest[];
  salesTheme?: string;
}

export interface AdminPostUpdatePayload {
  postId: string;
  request: AdminPostUpdateRequest;
}

export type PostCreationResponse = Post;

export interface PostWithStats extends Post {
  viewsCount: number;
  followsCount: number;
  sales?: FloorSale;
}

export interface StreamerPostsResponse extends PageableResponse {
  posts: PostWithStats[];
}

export interface PostStatusRequestResponse {
  postId: string;
  postStatus: PostStatus;
}

export interface StreamerPostResponse extends Post {
  mediaUrl: string;
  mediaType: MediaType;
  products: PostProductResponse[];
  creator?: Creator;
  salesTheme?: string;
}

export interface PostProductResponse {
  baseProductNo: string;
  variantId?: string;
}

export interface PostUpdateResponse {
  id: string;
}
export interface PostUpdateResponse {
  id: string;
}
