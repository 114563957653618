import { Button, EButtonSize, EButtonType, Text } from '@hse24/shared-components';
import { FC } from 'react';
import styles from './CarouselSlide.module.scss';

interface CarouselSlideProps {
  backgroundImage?: string;
  title: string;
  description: string;
  buttonText: string;
  onButtonClick: () => void;
}

const CarouselSlide: FC<CarouselSlideProps> = ({
  backgroundImage,
  title,
  description,
  buttonText,
  onButtonClick,
}) => {
  const handleSlideClick = () => {
    onButtonClick();
  };
  return (
    <div onClick={handleSlideClick} className={styles.carouselSlide} data-testid="carousel-slide">
      <div className={styles.content}>
        <Text.J2 className={styles.title} data-testid="carousel-slide-title">
          {title}
        </Text.J2>
        <Text.B1 className={styles.description} data-testid="carousel-slide-description">
          {description}
        </Text.B1>
      </div>
      <Button
        type={EButtonType.BLANK}
        size={EButtonSize.SMALL}
        className={styles.button}
        onClick={onButtonClick}
        data-testid="carousel-slide-button"
      >
        {buttonText}
      </Button>
      {!!backgroundImage && <img src={backgroundImage} alt="" />}
    </div>
  );
};

export default CarouselSlide;
