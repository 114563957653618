import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import { Platform } from '../../../common/types';

export function AdminPastShowsTableColumnsToggle(
  onlyLivePerformance: boolean,
  isPlatform: (p: Platform) => boolean | undefined,
  isTotalShouldBeDisplayed: () => boolean
): Partial<GridColumnVisibilityModel> {
  const forced: Partial<GridColumnVisibilityModel> = {};

  forced['floorSaleLive'] = onlyLivePerformance && isTotalShouldBeDisplayed();
  forced['webFloorSalesLive'] = onlyLivePerformance && !!isPlatform(Platform.WEB);
  forced['appFloorSalesLive'] = onlyLivePerformance && !!isPlatform(Platform.APP);
  forced['iosFloorSalesLive'] = onlyLivePerformance && !!isPlatform(Platform.IOS);
  forced['androidFloorSalesLive'] = onlyLivePerformance && !!isPlatform(Platform.ANDROID);

  forced['floorSaleTotal'] = !onlyLivePerformance && isTotalShouldBeDisplayed();
  forced['webFloorSalesTotal'] = !onlyLivePerformance && !!isPlatform(Platform.WEB);
  forced['appFloorSalesTotal'] = !onlyLivePerformance && !!isPlatform(Platform.APP);
  forced['iosFloorSalesTotal'] = !onlyLivePerformance && !!isPlatform(Platform.IOS);
  forced['androidFloorSalesTotal'] = !onlyLivePerformance && !!isPlatform(Platform.ANDROID);

  forced['ordersLive'] = onlyLivePerformance && isTotalShouldBeDisplayed();
  forced['webOrdersCountLive'] = onlyLivePerformance && !!isPlatform(Platform.WEB);
  forced['appOrdersCountLive'] = onlyLivePerformance && !!isPlatform(Platform.APP);
  forced['iosOrdersCountLive'] = onlyLivePerformance && !!isPlatform(Platform.IOS);
  forced['androidOrdersCountLive'] = onlyLivePerformance && !!isPlatform(Platform.ANDROID);

  forced['ordersTotal'] = !onlyLivePerformance && isTotalShouldBeDisplayed();
  forced['webOrdersCountTotal'] = !onlyLivePerformance && !!isPlatform(Platform.WEB);
  forced['appOrdersCountTotal'] = !onlyLivePerformance && !!isPlatform(Platform.APP);
  forced['iosOrdersCountTotal'] = !onlyLivePerformance && !!isPlatform(Platform.IOS);
  forced['androidOrdersCountTotal'] = !onlyLivePerformance && !!isPlatform(Platform.ANDROID);

  forced['conversionLive'] =
    onlyLivePerformance &&
    !(
      isPlatform(Platform.IOS) ||
      isPlatform(Platform.ANDROID) ||
      isPlatform(Platform.APP) ||
      isPlatform(Platform.WEB)
    );

  forced['conversionIosLive'] = onlyLivePerformance && !!isPlatform(Platform.IOS);
  forced['conversionAndroidLive'] = onlyLivePerformance && !!isPlatform(Platform.ANDROID);
  forced['conversionAppLive'] = onlyLivePerformance && !!isPlatform(Platform.APP);
  forced['conversionWebLive'] = onlyLivePerformance && !!isPlatform(Platform.WEB);

  forced['conversionTotal'] =
    !onlyLivePerformance &&
    !(
      isPlatform(Platform.IOS) ||
      isPlatform(Platform.ANDROID) ||
      isPlatform(Platform.APP) ||
      isPlatform(Platform.WEB)
    );

  forced['conversionIosTotal'] = !onlyLivePerformance && !!isPlatform(Platform.IOS);
  forced['conversionAndroidTotal'] = !onlyLivePerformance && !!isPlatform(Platform.ANDROID);
  forced['conversionAppTotal'] = !onlyLivePerformance && !!isPlatform(Platform.APP);
  forced['conversionWebTotal'] = !onlyLivePerformance && !!isPlatform(Platform.WEB);

  forced['floorSalePerViewerLive'] = false;
  forced['floorSalePerViewerTotal'] = false;

  forced['floorSalePerMinuteLive'] = onlyLivePerformance;
  forced['floorSalePerMinuteTotal'] = !onlyLivePerformance;

  forced['viewsLive'] = false;
  forced['liveClassicViewsFromApp'] = false;
  forced['liveClassicViewsFromWeb'] = false;
  forced['liveClassicViewsFromIos'] = false;
  forced['liveClassicViewsFromAndroid'] = false;

  forced['viewsTotal'] = false;
  forced['totalClassicViewsFromApp'] = false;
  forced['totalClassicViewsFromWeb'] = false;
  forced['totalClassicViewsFromIos'] = false;
  forced['totalClassicViewsFromAndroid'] = false;

  forced['viewersLive'] = onlyLivePerformance;
  forced['liveClassicViewersFromApp'] = false;
  forced['liveClassicViewersFromWeb'] = false;
  forced['liveClassicViewersFromIos'] = false;
  forced['liveClassicViewersFromAndroid'] = false;

  forced['viewersTotal'] = !onlyLivePerformance;
  forced['totalClassicViewersFromApp'] = false;
  forced['totalClassicViewersFromWeb'] = false;
  forced['totalClassicViewersFromIos'] = false;
  forced['totalClassicViewersFromAndroid'] = false;

  forced['timeWatchLive'] = false;
  forced['timeWatchTotal'] = false;
  forced['timeWatchPerViewLive'] = false;
  forced['timeWatchPerViewTotal'] = false;

  forced['timeWatchPerViewerLive'] = onlyLivePerformance;
  forced['timeWatchPerViewerTotal'] = !onlyLivePerformance;

  forced['sharesLive'] = onlyLivePerformance;
  forced['sharesTotal'] = !onlyLivePerformance;

  forced['likesLive'] = onlyLivePerformance;
  forced['likesTotal'] = !onlyLivePerformance;

  forced['chatMessagesLive'] = onlyLivePerformance;
  forced['chatMessagesTotal'] = !onlyLivePerformance;

  return forced;
}
