import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import {
  DataGrid,
  deDE,
  GridColDef,
  GridFilterModel,
  GridRowIdGetter,
  GridRowParams,
  GridSortItem,
  GridToolbar,
} from '@mui/x-data-grid';
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';
import { GridFeatureMode } from '@mui/x-data-grid/models/gridFeatureMode';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowClassNameParams } from '@mui/x-data-grid/models/params';
import { useEffect, useRef, useState } from 'react';
import { Platform } from '../../../common/types';
import { AdminPastShowsTableColumnsToggle } from '../../../shows/component/AdminPastShowsTable/AdminPastShowsTableColumnsToggle';
import theme from '../../../theme/theme.module.scss';
import { TableFilter } from '../../state/creatorsSlice';
import CustomToolbar from './CustomToolbars/CustomToolbar';
import { Pageable } from './ServerSideDataGrid';

export interface DataGridTableProps {
  columns: GridColDef[];
  rows: GridRowsProp;
  loading: boolean;
  onRowClick?: (rowParams: GridRowParams) => void;
  getRowId?: GridRowIdGetter;
  initialPageSize?: number;
  initialSorting?: GridSortItem[];
  rowsPerPageOptions?: number[];
  height?: number;
  autoHeight?: boolean;
  quickFilterPlaceholder?: string;
  mode: GridFeatureMode;
  onSortModelChange?: (model: GridSortModel) => void;
  onQuickFilterChange?: (mode: GridFilterModel) => void;
  onPageSizeChange?: (pageSize: number) => void;
  onPageChange?: (page: number) => void;
  rowCount?: number;
  enableColumnSelector?: boolean;
  isExportEnabled?: boolean;
  sx?: SxProps<Theme>;
  getRowClassName?: (params: GridRowClassNameParams) => string;
  initialState?: TableFilter;
  onStateChange?: (state: TableFilter) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  dataGridProps?: {
    rowHeight?: number;
    headerHeight?: number;
  };
  useLocalStorage?: boolean;
  onExport?: () => void;
  onlyLivePerformance?: boolean;
  updatePageableCallback?: (pageable: Pageable) => void;
  platform?: Platform[];
  isPlatform?: (p: Platform) => boolean | undefined;
  isTotalShouldBeDisplayed?: () => boolean;
  quickFilterValue?: string;
}

const DataGridTable = ({
  rows,
  columns,
  loading,
  onRowClick = undefined,
  getRowId = undefined,
  initialPageSize = 5,
  initialSorting = undefined,
  rowsPerPageOptions = [5, 10, 20],
  height = 370,
  autoHeight = undefined,
  quickFilterPlaceholder = undefined,
  mode,
  onSortModelChange = undefined,
  onQuickFilterChange = undefined,
  onPageSizeChange = undefined,
  onPageChange = undefined,
  rowCount = undefined,
  enableColumnSelector = false,
  isExportEnabled = false,
  getRowClassName = undefined,
  sx = {},
  initialState,
  onStateChange,
  columnVisibilityModel,
  dataGridProps,
  onExport,
  useLocalStorage = false,
  onlyLivePerformance = false,
  updatePageableCallback,
  platform,
  isPlatform,
  isTotalShouldBeDisplayed,
  quickFilterValue,
}: DataGridTableProps) => {
  // If less items than 'initialPageSize' are shown, we apply the 'autoHeight' property and render a smaller table
  const lessRowsThanPageSize = rows.length <= initialPageSize;
  const dynamicHeight = lessRowsThanPageSize ? undefined : height;

  const DEFAULT_PAGE_SIZE = 10;
  const DEFAULT_PAGE = 0;

  //default selected columns
  const defaultVisibilityModel: GridColumnVisibilityModel = columns.reduce((model, column) => {
    model[column.field] = !column.hide;
    return model;
  }, {} as GridColumnVisibilityModel);

  const isInitialMount = useRef(true);

  // Initialize column visibility model from local storage or props

  const [localColumnVisibilityModel, setLocalColumnVisibilityModel] = useState(() => {
    if (!useLocalStorage) {
      return columnVisibilityModel || {};
    }
    const storedModel = localStorage.getItem('columnVisibilityModel');
    return storedModel ? JSON.parse(storedModel) : columnVisibilityModel || {};
  });

  // Handle column visibility changes and update local storage if needed
  const handleColumnVisibilityChange = (newModel: GridColumnVisibilityModel) => {
    setLocalColumnVisibilityModel(newModel);
    if (useLocalStorage) {
      localStorage.setItem('columnVisibilityModel', JSON.stringify(newModel));
    }
  };

  // Initialize page size from local storage or props
  const [localPageSize, setLocalPageSize] = useState(() => {
    if (!useLocalStorage) {
      return initialPageSize;
    }
    const storedPageSize = localStorage.getItem('pageSize');
    return storedPageSize ? Number(storedPageSize) : initialPageSize;
  });

  // Initialize current page from local storage or props
  const [localPage, setLocalPage] = useState(() => {
    if (!useLocalStorage) {
      return DEFAULT_PAGE;
    }
    const storedPage = localStorage.getItem('page');

    //pastStreamsPaginationSave is intiated to true from the ShowDetails component
    const useStoredPage = localStorage.getItem('pastStreamsPaginationSave');
    return storedPage && useStoredPage == 'true' ? Number(storedPage) : DEFAULT_PAGE;
  });

  // Handles page changes and update local storage if needed
  const handlePageChange = (page: number) => {
    setLocalPage(page);
    if (useLocalStorage) {
      localStorage.setItem('page', page.toString());
    }
    if (onPageChange) onPageChange(page);
  };

  // Handles page size changes and update local storage if needed
  const handlePageSizeChange = (pageSize: number) => {
    setLocalPageSize(pageSize);

    if (useLocalStorage) localStorage.setItem('pageSize', pageSize.toString());

    if (onPageSizeChange) onPageSizeChange(pageSize);
  };

  //Refreshing the calls with the saved pagination values when the component mounts

  useEffect(() => {
    if (useLocalStorage) {
      let storedPageSize = useLocalStorage ? localPageSize : initialPageSize;
      if (storedPageSize > 100) {
        storedPageSize = initialPageSize;
        setLocalPageSize(initialPageSize);
        if (useLocalStorage) {
          localStorage.setItem('pageSize', initialPageSize.toString());
        }
      }

      const storedPage = useLocalStorage ? localPage : DEFAULT_PAGE;
      const sortModel = initialSorting?.[0];

      const pageable: Pageable = {
        page: storedPage,
        pageSize: storedPageSize,
        sortField: sortModel?.field || 'startedAt',
        sortOrder: sortModel?.sort || 'asc',
      };

      updatePageableCallback?.(pageable);

      //setting pastStreamsPaginationSave to 'false' since the requirment is to only use the saved page value when navigating back from ShowDetails
      const useStoredPage = localStorage.getItem('pastStreamsPaginationSave');
      useStoredPage == 'true'
        ? localStorage.setItem('pastStreamsPaginationSave', 'false')
        : localStorage.setItem('page', DEFAULT_PAGE.toString());
    }
  }, []);

  // Handle reset: reset pagination and column visibility, and update local storage
  const handleReset = () => {
    setLocalPage(DEFAULT_PAGE);
    setLocalPageSize(DEFAULT_PAGE_SIZE);
    setLocalColumnVisibilityModel(defaultVisibilityModel);

    if (useLocalStorage) {
      localStorage.setItem('page', DEFAULT_PAGE.toString());
      localStorage.setItem('pageSize', DEFAULT_PAGE_SIZE.toString());
      localStorage.setItem('columnVisibilityModel', JSON.stringify(defaultVisibilityModel));
    }

    if (onStateChange) {
      onStateChange({
        pagination: { pageSize: DEFAULT_PAGE_SIZE, page: DEFAULT_PAGE },
        sorting: { sortModel: initialSorting || [] },
      });
    }

    const defaultPageable: Pageable = {
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      sortField: initialSorting?.[0]?.field || 'startedAt',
      sortOrder: initialSorting?.[0]?.sort || 'asc',
    };

    updatePageableCallback?.(defaultPageable);
  };

  const areSelectedColumnsDiffThenDefaultColumns = (
    model1: GridColumnVisibilityModel,
    model2: GridColumnVisibilityModel
  ): boolean => {
    const keys1 = Object.keys(model1);
    const keys2 = Object.keys(model2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (model1[key] !== model2[key]) return false;
    }

    return true;
  };

  const displayResetButton =
    localPage !== DEFAULT_PAGE ||
    localPageSize !== DEFAULT_PAGE_SIZE ||
    !areSelectedColumnsDiffThenDefaultColumns(localColumnVisibilityModel, defaultVisibilityModel);

  //Toggle selects those fields based on if we filter for liveshows/Platform or not
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false; // Mark that the initial mount is complete
      return; // Exit early on the first render
    }

    if (!useLocalStorage) return;

    setLocalColumnVisibilityModel((prev: GridColumnVisibilityModel) => {
      const updated = { ...prev };
      const toggleFields = AdminPastShowsTableColumnsToggle(
        onlyLivePerformance,
        isPlatform || (() => undefined),
        isTotalShouldBeDisplayed || (() => false)
      );
      for (const colKey in toggleFields) {
        updated[colKey] = toggleFields[colKey] ?? false;
      }
      localStorage.setItem('columnVisibilityModel', JSON.stringify(updated));
      return updated;
    });
  }, [onlyLivePerformance, platform, useLocalStorage]);

  const csvOptions = isExportEnabled
    ? {
        utf8WithBom: true,
        delimiter: ';',
        getRowsToExport: onExport,
      }
    : { disableToolbarButton: true };

  const toolbarComponent = useLocalStorage
    ? () => (
        // Use CustomToolbar when useLocalStorage is true
        <CustomToolbar
          showResetButton={displayResetButton}
          onReset={handleReset}
          csvOptions={csvOptions}
          quickFilterPlaceholder={quickFilterPlaceholder}
          filterMode={mode}
          value={quickFilterValue}
        />
      )
    : GridToolbar; // Use default GridToolbar when useLocalStorage is false

  return (
    <div style={{ height: dynamicHeight, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        {...(useLocalStorage && { page: localPage })}
        // ⚠️ Warning: Ensure that the pageSize does not exceed 100, as the DataGrid component will throw an error if this limit is breached.
        {...(useLocalStorage && localPageSize <= 100 && { pageSize: localPageSize })}
        onRowClick={onRowClick}
        isRowSelectable={() => false}
        getRowId={getRowId}
        initialState={
          initialState || {
            pagination: {
              pageSize: initialPageSize,
            },
            sorting: {
              sortModel: initialSorting,
            },
          }
        }
        rowsPerPageOptions={rowsPerPageOptions}
        sortingOrder={['asc', 'desc']}
        disableColumnMenu={true}
        autoHeight={autoHeight !== undefined ? autoHeight : lessRowsThanPageSize}
        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        /* Toolbar with quick filter */
        componentsProps={{
          toolbar: {
            showQuickFilter:
              onQuickFilterChange !== undefined || quickFilterPlaceholder !== undefined,
            quickFilterProps: {
              debounceMs: 500,
              placeholder: quickFilterPlaceholder ?? 'Suche...',
            },
            printOptions: { disableToolbarButton: true }, // disabling print + csv will hide the export button completely
            csvOptions,
          },
        }}
        disableColumnFilter
        disableColumnSelector={!enableColumnSelector}
        disableDensitySelector
        /* Change handler for server-side pagination etc.*/
        sortingMode={mode}
        filterMode={mode}
        paginationMode={mode}
        rowCount={rowCount}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onQuickFilterChange}
        onPageSizeChange={useLocalStorage ? handlePageSizeChange : onPageSizeChange}
        onPageChange={useLocalStorage ? handlePageChange : onPageChange}
        components={
          onQuickFilterChange || quickFilterPlaceholder
            ? {
                Toolbar: toolbarComponent,
              }
            : undefined
        }
        /* Styles */
        sx={{
          color: '#252525',
          background: 'white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600,
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '& .MuiTablePagination-displayedRows': {
            marginTop: 0,
          },
          '& .MuiDataGrid-row:hover': {
            background: theme.paleOrange,
          },
          '& .MuiDataGrid-cell': {
            padding: '0 16px',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:hover': {
            cursor: onRowClick ? 'pointer' : 'inherit',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader': {
            padding: '0 16px',
          },
          '& .MuiDataGrid-columnSeparator': {
            color: 'transparent',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTopWidth: 2,
          },
          border: 'unset',
          ...sx,
        }}
        getRowClassName={getRowClassName}
        onStateChange={state =>
          onStateChange &&
          onStateChange({
            pagination: state.pagination,
            sorting: state.sorting,
          })
        }
        columnVisibilityModel={useLocalStorage ? localColumnVisibilityModel : columnVisibilityModel}
        {...(useLocalStorage && { onColumnVisibilityModelChange: handleColumnVisibilityChange })}
        {...dataGridProps}
      />
    </div>
  );
};

export default DataGridTable;
