import { LoadingSpinner } from '@hse24/shared-components';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../../assets';
import { secondsToMmSs } from '../../../../common/utils';
import Icon from '../../../../components/Icon/Icon';
import { PostUploadStatus } from '../../../model/post';
import {
  FileMetaData,
  selectExhibitedFile,
  setExhibitedFile,
  uploadPostMediaToS3,
} from '../../../state/postsSlice';
import { getFileExtension, isImage, isVideo } from '../../../utils/MediaUtils';
import styles from './PostMediaPreview.module.scss';

interface PostMediaPreviewProps {
  file: FileMetaData;
}
export const PostMediaPreview = ({ file }: PostMediaPreviewProps) => {
  const [videoDuration, setVideoDuration] = useState<null | string>(null);
  const exhibitedFile = useSelector(selectExhibitedFile);

  const status: PostUploadStatus | undefined = file.uploadStatus;
  const dispatch = useDispatch();

  useEffect(() => {
    const videoElement = document.createElement('video');

    if (file.url && isVideo(file.type)) {
      videoElement.src = file.url;

      videoElement.addEventListener('loadedmetadata', () => {
        const duration = videoElement.duration;
        setVideoDuration(secondsToMmSs(duration));
      });

      videoElement.load();
    }
  }, []);

  useEffect(() => {
    if (status === PostUploadStatus.IN_PROGRESS) {
      dispatch(uploadPostMediaToS3({ fileUrl: file.url, preSignedUrl: file.preSignedUrl }));
    }
  }, []);

  return (
    <>
      {file.url && (
        <div
          key={file.name}
          onClick={() => {
            dispatch(setExhibitedFile(file));
          }}
          data-testid="post-media-preview"
          className={cx(styles.post_media_preview, {
            [styles.selected_post_media_preview]: exhibitedFile && exhibitedFile.key === file.key,
          })}
        >
          {(status === undefined || status === PostUploadStatus.IN_PROGRESS) && (
            <LoadingSpinner className={styles.icon} />
          )}
          {isImage(file.type) ? (
            <img className={styles.media} src={file.url} alt={file.name} />
          ) : (
            <>
              <div data-testid="video_badge" className={styles.video_badge}>
                <Icon icon={Icons.play} dataTestId="play-icon" height={16} width={16} />
                <p data-testid="duration">{videoDuration}</p>
              </div>
              <video playsInline preload="auto" data-testid="video" className={styles.media}>
                <source
                  data-testid="video-source"
                  src={file.url}
                  type={`video/${getFileExtension(file.key)}`}
                />
                Your browser does not support the video tag.
              </video>
            </>
          )}
        </div>
      )}
    </>
  );
};
