import DateFilter from '../DateFilter';
import {
  DateFilterMode,
  DateFilterType,
  DateFilterTypes,
  DateRangeWithSelectedFilter,
} from '../dateFilterTypes';

export type PastDateFilters = Extract<
  DateFilterTypes,
  | DateFilterTypes.YESTERDAY
  | DateFilterTypes.TODAY
  | DateFilterTypes.MONTH
  | DateFilterTypes.CUSTOM
  | DateFilterTypes.LAST_7_DAYS
  | DateFilterTypes.LAST_14_DAYS
  | DateFilterTypes.LAST_28_DAYS
>;

interface DateFilterInPastProps {
  onDateFiltersChange: (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) => void;
  selectedFilter?: DateFilterType;
  selectedDateFrom?: string;
  selectedDateTo?: string;
  shouldIncludeToday?: boolean;
}

const DateFilterInPast = ({
  onDateFiltersChange,
  selectedFilter = DateFilterTypes.LAST_28_DAYS,
  selectedDateFrom: savedDateFrom,
  selectedDateTo: savedDateTo,
  shouldIncludeToday: shouldIncludeToday,
}: DateFilterInPastProps) => {
  return (
    <DateFilter
      selectedFilter={selectedFilter}
      onDateFiltersChange={onDateFiltersChange}
      dateFilterMode={DateFilterMode.PAST}
      selectedDateFrom={savedDateFrom}
      selectedDateTo={savedDateTo}
      shouldIncludeToday={shouldIncludeToday}
    />
  );
};

export default DateFilterInPast;
