import { AppBar, Grid, Tab, Tabs, Toolbar } from '@mui/material';
import { BaseSyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Role } from '../../../auth/role';
import {
  selectUserIsClassicStreamer,
  selectUserIsHelloStreamer,
} from '../../../auth/state/authSlice';
import DesktopCreateContent from '../../../components/CreateContent/DesktopCreateContent/DesktopCreateContent';
import { featureFlagValue } from '../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../config/FeatureFlags';
import routePaths from '../../../routes/routePaths';
import AccountMenu from '../AccountMenu/AccountMenu';
import StreamersSymbol from '../HelloStreamersSymbol/StreamersSymbol';
import {
  desktopNavigationTabs,
  getNavigationItemsForUserRoles,
  NavigationItemType,
  selectActiveNavigationItem,
} from '../navigationItems';
import styles from './DesktopNavbar.module.scss';

export const DesktopNavbar = () => {
  // TODO Clean after isContentCreationEnabled FLAG,
  const cleanedDesktopNavigationTabs = {
    ...desktopNavigationTabs,
    [Role.STREAMER_CLASSIC]: desktopNavigationTabs[Role.STREAMER_CLASSIC].map(item =>
      item.name === 'Erstellen' ? { ...item, path: routePaths.creator.addShow } : item
    ),
  };
  //
  const isHelloStreamer = useSelector(selectUserIsHelloStreamer);
  const isGoodbyeMessageForHelloStreamerEnabled = featureFlagValue(
    AvailableFlag.goodbyeMessageForHelloStreamer
  );
  const isClassicStreamer = useSelector(selectUserIsClassicStreamer);

  const navigationTabs =
    isHelloStreamer && isGoodbyeMessageForHelloStreamerEnabled
      ? {
          ...cleanedDesktopNavigationTabs,
          [Role.STREAMER_HELLO]: cleanedDesktopNavigationTabs[Role.STREAMER_HELLO].filter(
            item => item.name != 'Erstellen'
          ),
        }
      : cleanedDesktopNavigationTabs;

  const availableTabs = useSelector(getNavigationItemsForUserRoles(navigationTabs));
  const activeTabIndexOrFalse = useSelector(selectActiveNavigationItem(navigationTabs));
  const history = useHistory();

  const handleChange = (_: BaseSyntheticEvent, selectedIndex: number) => {
    const path = availableTabs[selectedIndex]?.path;
    path && history.location.pathname !== path && history.push(path);
  };

  return (
    <AppBar color="inherit" className={styles.appbar} data-testid="desktop-navbar">
      <Toolbar className={styles.toolbar}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item>
            <Grid container spacing={4} alignItems={'center'}>
              <Grid item>
                <StreamersSymbol />
              </Grid>
              <Grid item>
                <Tabs
                  sx={{
                    '.MuiTab-root.Mui-selected': {
                      color: '#252525',
                      fontWeight: '700',
                    },
                  }}
                  value={activeTabIndexOrFalse}
                  onChange={handleChange}
                  aria-label="nav tabs"
                >
                  {availableTabs.map(item =>
                    item?.type == NavigationItemType.BUTTON ? (
                      <DesktopCreateContent key={item.name} item={item} />
                    ) : (
                      <Tab
                        className={styles.link}
                        label={item.name}
                        key={item.name}
                        disableRipple
                        data-testid={'nav-tab-item'}
                      />
                    )
                  )}
                </Tabs>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>{!isClassicStreamer && <AccountMenu />}</Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
