import cx from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BarChart from '../../../components/Charts/BarChart/BarChart';
import DateFilterInPast from '../../../components/DateFilter/DateFilterInPast/DateFilterInPast';
import {
  DateFilterTypes,
  DateRange,
  DateRangeWithSelectedFilter,
} from '../../../components/DateFilter/dateFilterTypes';
import {
  getCreatorPaymentsDetails,
  selectDailyStreamedSeconds,
  selectPaymentsDailyGeneratedSales,
  selectPaymentsGeneratedSales,
  selectStreamedSeconds,
} from '../../../payments/state/paymentsSlice';
import { addEURCurrency } from '../../../utils/addEURCurrency';
import {
  mapDailyGeneratedSalesToBarChartData,
  mapDailyStreamedSecondsToBarChartData,
  mapPaymentsGeneratedSalesToSummary,
  mapStreamedSecondsToSummary,
} from '../../../utils/chartDataMapper';
import formatTime from '../../../utils/formatters/formatTime/formatTime';
import styles from './CreatorDetailsPerformance.module.scss';

function CreatorDetailsPerformance({ creatorId }: { creatorId: string }) {
  const dispatch = useDispatch();

  const paymentsGeneratedSales = useSelector(selectPaymentsGeneratedSales);
  const paymentsDailyGeneratedSales = useSelector(selectPaymentsDailyGeneratedSales);
  const dailyStreamedSeconds = useSelector(selectDailyStreamedSeconds);
  const streamedSeconds = useSelector(selectStreamedSeconds);

  const [dateRange, setDateRange] = useState<DateRange>();

  const dailyGeneratedSales = useMemo(() => {
    if (paymentsDailyGeneratedSales !== undefined)
      return mapDailyGeneratedSalesToBarChartData(paymentsDailyGeneratedSales);
    return [];
  }, [paymentsDailyGeneratedSales]);

  const chartDailyStreamedMinutes = useMemo(() => {
    if (dailyStreamedSeconds !== undefined)
      return mapDailyStreamedSecondsToBarChartData(dailyStreamedSeconds);
    return [];
  }, [dailyStreamedSeconds]);

  useEffect(() => {
    if (dateRange) {
      dispatch(getCreatorPaymentsDetails({ dateRange, creatorId }));
    }
  }, []);

  const onDateFiltersChange = (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) => {
    const dateRange: DateRange = {
      startDate: dateRangeWithSelectedFilter.startDate,
      endDate: dateRangeWithSelectedFilter.endDate,
    };
    setDateRange(dateRange);
    dispatch(getCreatorPaymentsDetails({ dateRange, creatorId }));
  };

  return (
    <div className={styles.wrapper}>
      <DateFilterInPast
        onDateFiltersChange={onDateFiltersChange}
        selectedFilter={DateFilterTypes.MONTH}
      />
      <div className={cx(styles.charts)}>
        <BarChart
          chartData={dailyGeneratedSales}
          label={'Umsatz im ausgewählten Zeitraum'}
          yTickValueFormatter={addEURCurrency}
          tooltipLabelFormatter={addEURCurrency}
          summary={mapPaymentsGeneratedSalesToSummary(paymentsGeneratedSales)}
        />
        <BarChart
          chartData={chartDailyStreamedMinutes}
          label={'Streamzeit im ausgewählten Zeitraum'}
          yTickValueFormatter={formatTime}
          tooltipLabelFormatter={formatTime}
          summary={mapStreamedSecondsToSummary(streamedSeconds)}
        />
      </div>
    </div>
  );
}

export default CreatorDetailsPerformance;
