import { IconWithText, Text } from '@hse24/shared-components';
import { useHistory } from 'react-router-dom';
import Icons from '../../../../assets';
import Icon from '../../../../components/Icon/Icon';
import t from '../../../../constants/translation';
import routePaths from '../../../../routes/routePaths';
import styles from './CreationOverview.module.scss';

const CreationOverview = () => {
  const history = useHistory();
  const handleCreateStream = () => history.push(routePaths.creator.addShow);
  const handleCreatePost = () => history.push(routePaths.creator.addPost);

  return (
    <div className={styles.creation_overview_container} data-testid="creation-overview-container">
      <Text.B2 className={styles.creation_text} data-testid="creation-text">
        {t.creators.post['What do you want to create?']}
      </Text.B2>
      <section data-testid="creation-section">
        <div className={styles.links}>
          <div
            className={styles.link}
            onClick={() => {
              handleCreateStream();
            }}
          >
            <IconWithText icon={<Icon icon={Icons.calendarIcon} />}>
              <Text.Body>{t.admins.survey.Livestream}</Text.Body>
            </IconWithText>
          </div>
          <div
            className={styles.link}
            onClick={() => {
              handleCreatePost();
            }}
          >
            <IconWithText icon={<Icon icon={Icons.play} />}>
              <Text.Body> {t.creators.post['Video post']}</Text.Body>
            </IconWithText>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreationOverview;
