import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Voucher } from '../model/voucher';

export interface VouchersState {
  suggestedVouchers: Voucher[];
  isLoading: boolean;
}

export const vouchersInitialState: VouchersState = {
  suggestedVouchers: [],
  isLoading: false,
};

export const fetchSuggestedVouchers = createAction('vouchers/suggestedVouchers');

export const vouchersSlice = createSlice({
  name: 'vouchers',
  initialState: vouchersInitialState,
  reducers: {
    setSuggestedVouchers: (state, action: PayloadAction<Voucher[]>) => {
      state.suggestedVouchers = action.payload;
    },
    setSuggestedVouchersIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});
const selectVouchersState = (state: RootState) => state[vouchersSlice.name];

export const selectSuggestedVouchers = createSelector(
  selectVouchersState,
  state => state.suggestedVouchers
);

export const selectSuggestedVouchersIsLoading = createSelector(
  selectVouchersState,
  state => state.isLoading
);

export const { setSuggestedVouchers, setSuggestedVouchersIsLoading } = vouchersSlice.actions;

export default vouchersSlice.reducer;
