import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateFilterInPast from '../../../components/DateFilter/DateFilterInPast/DateFilterInPast';
import { DateRangeWithSelectedFilter } from '../../../components/DateFilter/dateFilterTypes';
import AddCreatorDialogWithButton from '../../../creators/components/AddCreatorDialogWithButton/AddCreatorDialogWithButton';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import OverAllPerformance from '../../components/OverAllPerformance/OverAllPerformance';
import StreamersTable from '../../components/StreamersTable/StreamersTable';
import {
  getAgencyDetails,
  selectAgencyCommission,
  selectAgencyDetailsLoading,
  selectAgencyGeneratedSales,
  selectAgencyStreamedSeconds,
  selectAgencyStreamers,
  selectAgencyStreamersOverviewDateFilter,
  setAgencyStreamersOverviewDateFilter,
} from '../../state/agenciesSlice';
import styles from './AgencyStreamersOverviewPage.module.scss';

const AgencyStreamersOverviewPage = () => {
  const dispatch = useDispatch();

  const streamers = useSelector(selectAgencyStreamers) ?? [];

  const dateRange = useSelector(selectAgencyStreamersOverviewDateFilter);
  const streamedSeconds = useSelector(selectAgencyStreamedSeconds);
  const agencyCommission = useSelector(selectAgencyCommission);
  const agencyGeneratedSales = useSelector(selectAgencyGeneratedSales);
  const isLoading = useSelector(selectAgencyDetailsLoading);

  const onDateFiltersChange = (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) => {
    dispatch(setAgencyStreamersOverviewDateFilter(dateRangeWithSelectedFilter));
  };

  useEffect(() => {
    if (dateRange) {
      dispatch(getAgencyDetails({ dateRange }));
    }
  }, [dateRange]);

  const AgencyStreamersOverview = () => (
    <Box className={styles.container}>
      <h1 className={styles.header}>Performance aller Streamer</h1>

      <DateFilterInPast
        selectedFilter={dateRange?.dateFilter}
        selectedDateTo={dateRange?.endDate}
        selectedDateFrom={dateRange?.startDate}
        onDateFiltersChange={onDateFiltersChange}
        shouldIncludeToday
      />

      <OverAllPerformance
        streamedSeconds={streamedSeconds}
        agencyCommission={agencyCommission}
        agencyGeneratedSales={agencyGeneratedSales}
      />
      <Box className={styles.table_header}>
        <h1>Streamer</h1>
        <div className={styles.table_actions}>
          <AddCreatorDialogWithButton />
        </div>
      </Box>
      <StreamersTable creators={streamers} isLoading={isLoading} />
    </Box>
  );

  return (
    <PageContainer pageToRender={AgencyStreamersOverview()} backgroundColor={theme.whiteLilac} />
  );
};

export default AgencyStreamersOverviewPage;
