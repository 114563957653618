import { Box } from '@mui/material';
import cx from 'classnames';
import React from 'react';
import Config from '../../../config/config';
import { featureFlagValue } from '../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../config/FeatureFlags';
import { ProductPreview } from '../../../shows/model/shows';
import { sendMessageToNativeApp } from '../../../storage/dataExchangerService';
import { buildClickMessageEvent, ClickEventElement } from '../../../storage/nativeMessages';
import ImagePreview from '../ImagePreview/ImagePreview';
import styles from './ProductSlider.module.scss';
import VoucherLabel from './VoucherLabel';

interface Props {
  className?: string;
  productsPreview: ProductPreview[];
  isLarge?: boolean;
  forAdmin?: boolean;
}

const ProductSlider = ({ className, productsPreview, isLarge, forAdmin = false }: Props) => {
  const isInApp = featureFlagValue(AvailableFlag.isInApp);

  const imageClickedHandler = (productId: string) => {
    if (isInApp) {
      sendMessageToNativeApp(buildClickMessageEvent(ClickEventElement.DISPLAY_PDP, productId));
    } else {
      const path = `${Config.env.baseUrl}/p/product/${productId}`;
      window.open(path);
    }
  };

  return (
    <div className={className} data-testid="product-slider">
      <div className={styles.product_slider}>
        {productsPreview.map(productPreview => {
          return (
            <Box
              key={productPreview.baseProductNo}
              className={cx(styles.product_tile, { [styles.large]: isLarge })}
              onClick={() => {
                imageClickedHandler(productPreview.baseProductNo);
              }}
              data-testid="product-preview"
            >
              <ImagePreview
                imageUrl={productPreview.imageUri}
                alt={productPreview.imageUri ?? `${productPreview.baseProductNo} alt`}
                size={320}
              />
              <div
                className={cx(styles.image_overlay, {
                  [styles.dark_layer]: productPreview.soldOut,
                })}
              />
              <VoucherLabel product={productPreview} forAdmin={forAdmin} />
            </Box>
          );
        })}
      </div>
    </div>
  );
};

export default ProductSlider;
