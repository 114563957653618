import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import t from '../../../constants/translation';
import { EUROPE_BERLIN_TIMEZONE } from '../../../shows/constants';
import {
  UnknownVoucherType,
  Voucher,
  VoucherResponse,
  VoucherType,
} from '../../../vouchers/model/voucher';
import { germanMoneyFormat } from '../germanFormat/germanFormats';

const DATE_FORMAT = 'DD.MM.YYYY HH:mm';
dayjs.extend(utc);
dayjs.extend(timezone);
export const formatVoucherValidityDate = (voucher?: Voucher) => {
  return voucher == null
    ? '-'
    : dayjs(voucher.validityStartTime).tz(EUROPE_BERLIN_TIMEZONE).format(DATE_FORMAT) +
        ' bis ' +
        dayjs(voucher.validityEndTime).tz(EUROPE_BERLIN_TIMEZONE).format(DATE_FORMAT);
};

export const formatVoucherValueByType = (voucher?: Voucher): string => {
  if (voucher == null) return '-';
  switch (voucher?.type) {
    case VoucherType.FREE_SHIPPING:
      return 'Free shipping';
    case VoucherType.VALUE:
      return germanMoneyFormat(voucher.value);
    case VoucherType.PERCENTAGE:
      return voucher.value + '%';
  }
};

export const getVoucherLabel = (voucher?: VoucherResponse, numberOnly = false) => {
  let labelText = '';
  const voucherType = voucher?.voucherType;
  const voucherValue = voucher?.voucherValue;
  switch (voucherType) {
    case VoucherType.PERCENTAGE:
      labelText = `-${voucherValue}% ${numberOnly ? '' : `<br/>${t.common['Voucher']}`}`;
      break;
    case VoucherType.VALUE:
      labelText = `-${voucherValue}€ ${numberOnly ? '' : `<br/>${t.common['Voucher']}`}`;
      break;
    case VoucherType.FREE_SHIPPING:
      labelText = t.creators.voucher['free_shipping'];
      break;
    case UnknownVoucherType:
      labelText = t.common['Voucher'];
      break;
  }

  return labelText;
};
