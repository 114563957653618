import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Audience } from '../../../common/types';
import AudienceFilter from '../../../components/AudienceFilter/AudienceFilter';
import DateFilterInPast from '../../../components/DateFilter/DateFilterInPast/DateFilterInPast';
import { DateRangeWithSelectedFilter } from '../../../components/DateFilter/dateFilterTypes';
import Header from '../../../components/Header/Header';
import t from '../../../constants/translation';
import routePaths from '../../../routes/routePaths';
import {
  fetchAdminPostsOverview,
  selectAdminPostsOverview,
  setAdminPostsOverview,
} from '../../state/postsSlice';
import AdminPostsTable from '../AdminPostsTable/AdminPostsTable';
import styles from './AdminPostsOverview.module.scss';

function AdminPostsOverview() {
  const history = useHistory();
  const dispatch = useDispatch();
  const postsOverview = useSelector(selectAdminPostsOverview);
  const dateRange = postsOverview.dateFilter;
  const audienceFilter = postsOverview.queryParams.audience;

  useEffect(() => {
    if (dateRange) {
      const { from, to } = postsOverview.queryParams;
      const { startDate, endDate } = dateRange;
      if (from != startDate || to != endDate) {
        const queryParams = {
          ...postsOverview.queryParams,
          from: startDate,
          to: endDate,
        };
        dispatch(setAdminPostsOverview({ queryParams }));
      }
    }
  }, [dateRange]);

  useEffect(() => {
    if (postsOverview.queryParams) {
      dispatch(fetchAdminPostsOverview(postsOverview.queryParams));
    }
  }, [postsOverview.queryParams]);

  const onDateFiltersChange = (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) => {
    dispatch(setAdminPostsOverview({ dateFilter: dateRangeWithSelectedFilter }));
  };

  const onAudienceFilterChange = (audience: Audience[]) => {
    const queryParams = { ...postsOverview.queryParams, audience };
    dispatch(setAdminPostsOverview({ queryParams }));
  };

  const handleOnClick = () => history.push(routePaths.hseEmployee.createPost);

  return (
    <>
      <Header
        title={t.admins.post.Posts}
        onButtonClick={handleOnClick}
        buttonText={t.admins.post['Create Post']}
      />
      <Box display={'flex'} flexDirection={'column'} gap={3}>
        <Box className={styles.table_filter}>
          <Box>
            <DateFilterInPast
              onDateFiltersChange={onDateFiltersChange}
              selectedDateFrom={dateRange?.startDate}
              selectedDateTo={dateRange?.endDate}
              selectedFilter={dateRange?.dateFilter}
              shouldIncludeToday
            />
          </Box>
          <Box>
            <Typography variant={'h6'} mb={2}>
              Audience
            </Typography>
            <AudienceFilter onSelect={onAudienceFilterChange} audienceFilter={audienceFilter} />
          </Box>
        </Box>
        <AdminPostsTable />
      </Box>
    </>
  );
}

export default AdminPostsOverview;
