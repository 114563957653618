import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BarChart from '../../../components/Charts/BarChart/BarChart';
import DateFilterInPast from '../../../components/DateFilter/DateFilterInPast/DateFilterInPast';
import {
  DateFilterTypes,
  DateRange,
  DateRangeWithSelectedFilter,
} from '../../../components/DateFilter/dateFilterTypes';
import StreamerCommissionInfoTooltip from '../../../components/StreamerCommissionInfoTooltip/StreamerCommissionInfoTooltip';
import t from '../../../constants/translation';
import { getCreatorPerformanceOverview } from '../../../creators/state/creatorsSlice';
import { addEURCurrency } from '../../../utils/addEURCurrency';
import {
  mapDailyGeneratedSalesToBarChartData,
  mapDailyStreamedSecondsToBarChartData,
  mapPaymentsGeneratedSalesToSummary,
  mapStreamedSecondsToSummary,
} from '../../../utils/chartDataMapper';
import formatTime from '../../../utils/formatters/formatTime/formatTime';
import InvoicesTable from '../../components/InvoicesTable/InvoicesTable';
import {
  getCreatorInvoices,
  getPaymentsDetails,
  selectCreatorInvoices,
  selectDailyStreamedSeconds,
  selectPaymentsDailyGeneratedSales,
  selectPaymentsGeneratedSales,
  selectStreamedSeconds,
} from '../../state/paymentsSlice';
import styles from './Payments.module.scss';

const Payments = () => {
  const dispatch = useDispatch();
  const paymentsGeneratedSales = useSelector(selectPaymentsGeneratedSales);
  const paymentsDailyGeneratedSales = useSelector(selectPaymentsDailyGeneratedSales);
  const invoices = useSelector(selectCreatorInvoices);
  const dailyStreamedSeconds = useSelector(selectDailyStreamedSeconds);
  const streamedSeconds = useSelector(selectStreamedSeconds);
  const [dateRange, setDateRange] = useState<DateRange>();

  const dailyGeneratedSales = useMemo(() => {
    if (paymentsDailyGeneratedSales !== undefined)
      return mapDailyGeneratedSalesToBarChartData(paymentsDailyGeneratedSales);
    return [];
  }, [paymentsDailyGeneratedSales]);

  const chartDailyStreamedSeconds = useMemo(() => {
    if (dailyStreamedSeconds !== undefined)
      return mapDailyStreamedSecondsToBarChartData(dailyStreamedSeconds);
    return [];
  }, [dailyStreamedSeconds]);

  useEffect(() => {
    if (dateRange) {
      dispatch(getPaymentsDetails(dateRange));
    }
    dispatch(getCreatorInvoices());
    dispatch(getCreatorPerformanceOverview());
  }, []);

  const onDateFiltersChange = (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) => {
    const dateRange: DateRange = {
      startDate: dateRangeWithSelectedFilter.startDate,
      endDate: dateRangeWithSelectedFilter.endDate,
    };
    setDateRange(dateRange);
    dispatch(getPaymentsDetails(dateRange));
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.commission_table_wrapper}>
          <StreamerCommissionInfoTooltip className={styles.commission} disabledTitle>
            <a>{t.creators['Commission table']}</a>
          </StreamerCommissionInfoTooltip>
        </div>

        <DateFilterInPast
          onDateFiltersChange={onDateFiltersChange}
          selectedFilter={DateFilterTypes.MONTH}
        />

        <div className={styles.charts_container}>
          <BarChart
            chartData={dailyGeneratedSales}
            label={`${t.creators['Net sales']} ${t.creators['in the selected period']}`}
            yTickValueFormatter={addEURCurrency}
            tooltipLabelFormatter={addEURCurrency}
            summary={mapPaymentsGeneratedSalesToSummary(paymentsGeneratedSales)}
          />
          <BarChart
            chartData={chartDailyStreamedSeconds}
            label={`${t.creators['Stream period']} ${t.creators['in the selected period']}`}
            yTickValueFormatter={formatTime}
            tooltipLabelFormatter={formatTime}
            summary={mapStreamedSecondsToSummary(streamedSeconds)}
          />
        </div>
        <InvoicesTable invoices={invoices ?? []} />
      </div>
    </div>
  );
};

export default Payments;
