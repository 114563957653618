import { endOfMonth, format, getMonth, getYear, startOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import t from '../../constants/translation';
import styles from './DateFilter.module.scss';
import DateFilterButtons from './DateFilterButtons/DateFilterButtons';
import {
  DateFilterMode,
  DateFilterType,
  DateFilterTypes,
  DateRangeWithSelectedFilter,
} from './dateFilterTypes';
import { resolveDateRange } from './dateFilterUtils';
import DatePickerSelector from './DatePickerSelector/DatePickerSelector';

export interface DateFiltersProps {
  onDateFiltersChange: (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) => void;
  dateFilterMode: DateFilterMode;
  selectedFilter: DateFilterType;
  selectedDateFrom?: string;
  selectedDateTo?: string;
  shouldIncludeToday?: boolean;
}

const DateFilter = ({
  onDateFiltersChange,
  dateFilterMode,
  selectedFilter,
  selectedDateFrom: savedDateFrom,
  selectedDateTo: savedDateTo,
  shouldIncludeToday: shouldIncludeToday,
}: DateFiltersProps) => {
  const [selectedDateFilter, setSelectedDateFilter] = useState(selectedFilter);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [isOpenInitially, setOpenInitially] = useState(false);

  useEffect(() => {
    const { dateFrom, dateTo } = resolveDateRange(
      selectedFilter,
      dateFilterMode,
      savedDateFrom,
      savedDateTo,
      shouldIncludeToday
    );
    setDateFrom(dateFrom);
    setDateTo(dateTo);
  }, [selectedFilter, dateFilterMode]);

  useEffect(() => {
    if (dateFrom && dateTo) {
      onDateFiltersChange({
        startDate: format(dateFrom, 'yyyy-MM-dd'),
        endDate: format(dateTo, 'yyyy-MM-dd'),
        dateFilter: selectedDateFilter,
      });
    }
  }, [dateFrom, dateTo, selectedDateFilter]);

  const onChangeDateFrom = (date: Date | null) => {
    if (date !== null) {
      setDateFrom(date);

      // If selected filter is a month, there is no date range picker.
      // That's why we have to set dateTo manually.
      if (selectedDateFilter === DateFilterTypes.MONTH) {
        setDateTo(endOfMonth(date));
        const now = new Date();
        // If selected month, is a future one, we set the dateFrom manually
        if (getYear(now) < getYear(date) || getMonth(now) < getMonth(date)) {
          setDateFrom(startOfMonth(date));
        }
      }
    }
  };

  const onChangeDateTo = (date: Date | null) => {
    if (date !== null) {
      setDateTo(date);
    }
  };

  const onFilterSelected = (selectedFilter: DateFilterType) => {
    setSelectedDateFilter(selectedFilter);
    const { dateFrom, dateTo } = resolveDateRange(
      selectedFilter,
      dateFilterMode,
      savedDateFrom,
      savedDateTo,
      shouldIncludeToday
    );
    setDateFrom(dateFrom);
    setDateTo(dateTo);
    setOpenInitially(selectedFilter == DateFilterTypes.CUSTOM);
  };

  return (
    <>
      {dateFrom && dateTo && (
        <>
          <h2 className={styles.header}>{t.creators.Period}</h2>
          <DatePickerSelector
            selectedDateFilter={selectedDateFilter}
            selectedDateFrom={dateFrom}
            selectedDateTo={dateTo}
            onChangeDateFromCallback={onChangeDateFrom}
            onChangeDateToCallback={onChangeDateTo}
            dateFilterMode={dateFilterMode}
            isOpenInitially={isOpenInitially}
          />
          <DateFilterButtons
            onClickCallback={onFilterSelected}
            dateFilterMode={dateFilterMode}
            selectedDateFilter={selectedFilter}
          />
        </>
      )}
    </>
  );
};

export default DateFilter;
