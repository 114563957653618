import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box } from '@mui/material';
import React from 'react';
import { MediaType } from '../../api/postsRequestResponse';
import { Post } from '../../model/post';
import { MediaResponse } from '../../state/postsSlice';
import styles from '../StreamerPostsTable/StreamerPostsTable.module.scss';

interface Props {
  caption: string;
  post: Post;
  isInvisible: boolean;
}

function PostImage({ caption, post, isInvisible }: Props) {
  let imageUrl = post.imageUrl;

  if (post.medias && post.medias.length > 0) {
    const firstMedia: MediaResponse = post.medias[0];
    if (firstMedia.mediaType === MediaType.IMAGE) {
      imageUrl = firstMedia.url;
    } else if (
      firstMedia.mediaType === MediaType.VIDEO &&
      firstMedia.videoPayload &&
      firstMedia.videoPayload.thumbnailUrl
    ) {
      imageUrl = firstMedia.videoPayload.thumbnailUrl;
    }
  }

  return (
    <Box className={styles.post_image}>
      <img style={{ borderRadius: '8px' }} src={imageUrl} alt={caption} />
      {isInvisible && (
        <Box data-testid="post-image-visibility-icon" className={styles.invisible}>
          <VisibilityOffIcon fontSize={'small'} color={'inherit'} />
        </Box>
      )}
    </Box>
  );
}

export default PostImage;
