import { LoadingSpinner } from '@hse24/shared-components';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ChangeEvent, FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUserIsHSEEmployee } from '../../../auth/state/authSlice';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';
import t from '../../../constants/translation';
import ProductSelectionItem from '../../../products/components/ProductsSelection/ProductSelectionItem/ProductSelectionItem';
import ProductsSelection from '../../../products/components/ProductsSelection/ProductsSelection';
import { ProductTileInfo } from '../../../products/model/productTileInfo';
import {
  removeFromProductsSelectionSelectedProducts,
  selectProductSelection,
} from '../../../products/state/productsSlice';
import routePaths from '../../../routes/routePaths';
import DateSelector from '../../../shows/component/ScheduleShowForm/ShowStartTimePicker/DateSelector/DateSelector';
import ShowStartTimePicker from '../../../shows/component/ScheduleShowForm/ShowStartTimePicker/ShowStartTimePicker';
import useFormChanged from '../../../shows/utils/useFormChanged';
import { StreamerPostResponse } from '../../api/postsRequestResponse';
import { PostImageMetadata, StreamPostData } from '../../model/post';
import { selectCreatePost } from '../../state/postsSlice';
import CreatePostImage from '../CreatePostImage/CreatePostImage';
import styles from './CreateEditPostForm.module.scss';

export interface PostData {
  caption?: string | null;
  preview?: string;
  baseProductsNo: string[];
}

interface CreateEditPostFormProps {
  isCreate?: boolean;
  isOwnPost?: boolean;
  postToUpdate?: StreamerPostResponse;
  submitCallback: (values: StreamPostData) => void;
}

const CreateEditPostForm: FC<CreateEditPostFormProps> = ({
  isCreate = false,
  submitCallback,
  isOwnPost,
  postToUpdate,
}: CreateEditPostFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedProducts } = useSelector(selectProductSelection);
  const isAdmin: boolean = useSelector(selectUserIsHSEEmployee);

  const [isOpen, setIsOpen] = useState(false);
  const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);
  const [scheduleAt, setScheduleAt] = useState<Date | undefined>(
    postToUpdate?.scheduleAt ? dayjs(postToUpdate.scheduleAt).toDate() : undefined
  );
  const [postImage, setPostImage] = useState<PostImageMetadata | null>(
    postToUpdate?.imageUrl
      ? {
          imageUrl: postToUpdate?.imageUrl,
          fileExtension: 'jpeg',
        }
      : null
  );
  const [caption, setCaption] = useState<string | null>(postToUpdate?.caption ?? '');
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const formChanged = useFormChanged<PostData>(
    {
      caption,
      preview: postImage?.imageUrl,
      baseProductsNo: selectedProducts.map(p => p.baseProductNo),
    },
    postToUpdate
      ? {
          caption: postToUpdate.caption,
          preview: postToUpdate.imageUrl,
          baseProductsNo: postToUpdate.products.map(p => p.baseProductNo),
        }
      : null
  );

  const { loading } = useSelector(selectCreatePost);

  const displayButton = !(!isOwnPost && !isCreate);

  const onRemoveProduct = (product: ProductTileInfo) => {
    return dispatch(removeFromProductsSelectionSelectedProducts(product));
  };

  const showProducts = () => {
    setIsOpen(true);
  };

  const handleSubmit = () => {
    setIsFormSubmitted(true);
    submitCallback({ preview: postImage, caption, scheduleAt: scheduleAt?.toISOString() });
  };

  const onCaptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCaption(event.target.value);
  };

  const handleClose = () => setIsOpen(false);

  const handleCancel = () => {
    const path = isAdmin ? routePaths.hseEmployee.postsOverview : routePaths.creator.insights;
    history.push(path);
  };

  return (
    <Box className={styles.container}>
      <Grid
        container
        item
        xs={12}
        columnSpacing={{ xs: 0, md: 4 }}
        rowSpacing={{ xs: 4, md: 4 }}
        md={9}
        justifyContent={'center'}
      >
        <Grid item xs={12} md={4}>
          <Box className={styles.image_selection}>
            <CreatePostImage
              setCroppedPostImage={setPostImage}
              croppedPostImage={postImage}
              disabled={!isCreate}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box className={styles.post_details}>
            <Box className={styles.products}>
              <Typography variant={'h3'}>{t.creators.post['Add products']}</Typography>
              <Typography className={styles.secondary_text} variant="body1" gutterBottom>
                {t.creators.post['Add up to 50 products.*']}
              </Typography>
              <Paper onClick={showProducts} className={styles.card} elevation={0}>
                <IconButton>
                  <AddIcon color="primary" />
                </IconButton>
              </Paper>
              <Box className={styles.horizontal_list}>
                {selectedProducts.map(product => (
                  <ProductSelectionItem
                    key={product.baseProductNo}
                    product={product}
                    onRemoveHandler={() => onRemoveProduct(product)}
                    direction={'column'}
                  />
                ))}
              </Box>
            </Box>
            <Box className={styles.schedule_at} data-testid="scheduleAt time picker">
              <Typography variant="h3">{t.creators.post['ScheduleAt']}</Typography>
              <ShowStartTimePicker
                scheduledStartAt={scheduleAt?.toISOString() ?? ''}
                onClick={() => setIsDateSelectorOpen(true)}
                hasError={false}
              />
            </Box>
            {isDateSelectorOpen && (
              <DateSelector
                title={t.creators.post.DateSelectorTitle}
                isDialogOpen={isDateSelectorOpen}
                closeDialog={() => {
                  setIsDateSelectorOpen(false);
                }}
                plannedAt={scheduleAt ?? new Date()}
                updatePlannedAt={(date: Date) => {
                  setScheduleAt(date);
                }}
                fetchAllSlots={true}
              />
            )}
            <Box className={styles.caption}>
              <Typography variant={'h3'}>{t.creators.post['Details']}</Typography>
              <Box className={styles.field}>
                <TextField
                  placeholder="Bildunterschrift verfassen..."
                  multiline
                  rows={5}
                  value={caption}
                  onChange={onCaptionChange}
                  fullWidth
                />
              </Box>
            </Box>
            <Box className={styles.actions}>
              {displayButton && (
                <Button
                  disabled={
                    loading ||
                    selectedProducts.length < 1 ||
                    !caption?.trim() ||
                    (isCreate && !postImage)
                  }
                  onClick={handleSubmit}
                  variant={'contained'}
                  size={'large'}
                  disableElevation
                >
                  {!loading ? t.creators.post[isCreate ? 'Publish' : 'Edit'] : <LoadingSpinner />}
                </Button>
              )}
              <Button onClick={handleCancel} size={'large'} variant={'outlined'} disableElevation>
                {t.common['Cancel']}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {isOpen && <ProductsSelection handleClose={handleClose} />}
      <RouteLeavingGuard blockNavigation={formChanged} navigationPermitted={isFormSubmitted} />
    </Box>
  );
};

export default CreateEditPostForm;
