import { GridRowParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { selectUserIsHSEEmployee } from '../../../auth/state/authSlice';
import { Audience, Platform } from '../../../common/types';
import ServerSideDataGrid, {
  Pageable,
} from '../../../creators/components/DataGridTable/ServerSideDataGrid';
import routePaths from '../../../routes/routePaths';
import theme from '../../../theme/theme.module.scss';
import { ShowTotalAndLivePerformance } from '../../model/shows';
import {
  queryAdminPastShowsToExport,
  selectAdminDataToExport,
  selectAdminPastShows,
  selectAdminPastShowsOnlyLivePerformance,
  selectAdminPastShowsPlatform,
  selectAdminPastShowsQueryData,
  setAdminPastShowsToExport,
} from '../../state/showsSlice';
import { exportShowsCsv } from './AdminPastShowsCsvExport';
import { createAdminPastShowsTableColumns } from './AdminPastShowsTableColumns';

dayjs.extend(utc);
dayjs.extend(timezone);

interface AdminPastShowsTableProps {
  pageable: Pageable;
  pageableCallback: (pageable: Pageable) => void;
  useLocalStorage?: boolean;
}

const AdminPastShowsTable = ({
  pageable,
  pageableCallback,
  useLocalStorage,
}: AdminPastShowsTableProps) => {
  const showsQuery = useSelector(selectAdminPastShows);
  const showsToExport = useSelector(selectAdminDataToExport);
  const onlyLivePerformance = useSelector(selectAdminPastShowsOnlyLivePerformance);
  const platform = useSelector(selectAdminPastShowsPlatform);
  const isHSEEmployee = useSelector(selectUserIsHSEEmployee);
  const adminPastShowsQueryData = useSelector(selectAdminPastShowsQueryData);
  const history = useHistory();
  const dispatch = useDispatch();

  function getViewsFromPerformanceByAudienceAndSource(performance: ShowTotalAndLivePerformance) {
    // If the audience is CLASSIC, display the views number depending on the source selected
    const audience = adminPastShowsQueryData?.audience;
    if (audience?.length == 1 && audience.includes(Audience.CLASSIC)) {
      if (platform.length > 1) {
        return performance.viewsOverview.classicViewsTotal;
      } else if (platform.includes(Platform.APP)) {
        return performance.viewsOverview.classicAppViews;
      } else if (platform.includes(Platform.WEB)) {
        return performance.viewsOverview.classicWebViews;
      } else if (platform.includes(Platform.ANDROID)) {
        return performance.viewsOverview.classicAndroidViews;
      } else if (platform.includes(Platform.IOS)) {
        return performance.viewsOverview.classicIosViews;
      }
    }
    return performance.viewsOverview.views;
  }

  function getViewersFromPerformanceByAudienceAndSource(performance: ShowTotalAndLivePerformance) {
    // If the audience is CLASSIC, display the viewers number depending on the source selected
    const audience = adminPastShowsQueryData?.audience;
    if (audience?.length == 1 && audience.includes(Audience.CLASSIC)) {
      if (platform.length > 1) {
        return performance.viewersOverview.classicViewersTotal;
      } else if (platform.includes(Platform.APP)) {
        return performance.viewersOverview.classicAppViewers;
      } else if (platform.includes(Platform.WEB)) {
        return performance.viewersOverview.classicWebViewers;
      } else if (platform.includes(Platform.ANDROID)) {
        return performance.viewersOverview.classicAndroidViewers;
      } else if (platform.includes(Platform.IOS)) {
        return performance.viewersOverview.classicIosViewers;
      }
    }
    return performance.viewersOverview.viewers;
  }
  const isPlatform = (selectedPlatform: Platform) => {
    const audience = adminPastShowsQueryData?.audience;
    return platform.includes(selectedPlatform) && audience?.includes(Audience.CLASSIC);
  };
  const isTotalShouldBeDisplayed = () => {
    return platform.length == 0;
  };

  const columns = createAdminPastShowsTableColumns({
    onlyLivePerformance,
    platform,
    adminPastShowsQueryData,
    getViewsFromPerformanceByAudienceAndSource,
    getViewersFromPerformanceByAudienceAndSource,
    isPlatform,
    isTotalShouldBeDisplayed,
  });

  const navigateToShowDetails = (rowParams: GridRowParams) => {
    const path = generatePath(routePaths.hseEmployee.showDetails, { showId: rowParams.row.id });
    history.push(path);
  };

  useEffect(() => {
    if (showsToExport.shows.length > 0) {
      exportShowsCsv(
        showsToExport.shows,
        columns,
        getViewsFromPerformanceByAudienceAndSource,
        getViewersFromPerformanceByAudienceAndSource
      );
      dispatch(setAdminPastShowsToExport({ shows: [], hits: 0, pages: 0 }));
    }
  }, [showsToExport]);

  const onExport = () => {
    dispatch(queryAdminPastShowsToExport());
  };

  return (
    <ServerSideDataGrid
      columns={columns}
      rows={showsQuery.shows}
      rowCount={showsQuery.hits}
      loading={showsQuery.loading}
      rowsPerPageOptions={[10, 100, 1000]}
      autoHeight
      quickFilterPlaceholder={'Nach Stream suchen'}
      updatePageableCallback={pageableCallback}
      initialPageable={pageable}
      onRowClick={isHSEEmployee ? navigateToShowDetails : undefined}
      enableColumnSelector
      isExportEnabled
      onExport={onExport}
      getRowClassName={params => `published-show-row-theme--${params.row.isPublished}`}
      useLocalStorage={useLocalStorage}
      onlyLivePerformance={onlyLivePerformance}
      platform={platform}
      isPlatform={isPlatform}
      isTotalShouldBeDisplayed={isTotalShouldBeDisplayed}
      sx={{
        '& .published-show-row-theme--false': {
          color: theme.grayDark,
          '&:hover': {
            color: theme.grayDark,
          },
        },
      }}
    />
  );
};

export default AdminPastShowsTable;
