import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DateFilterInPast from '../../../components/DateFilter/DateFilterInPast/DateFilterInPast';
import { DateRangeWithSelectedFilter } from '../../../components/DateFilter/dateFilterTypes';
import Header from '../../../components/Header/Header';
import t from '../../../constants/translation';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import routePaths from '../../../routes/routePaths';
import theme from '../../../theme/theme.module.scss';
import AgenciesTable from '../../components/AgenciesList/AgenciesTable';
import { selectAgenciesDateRange, setAgenciesOverviewDateRange } from '../../state/agenciesSlice';
import styles from './AgenciesOverviewPage.module.scss';

const AgenciesOverviewPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const agenciesDateRange = useSelector(selectAgenciesDateRange);
  const handleOnClick = () => history.push(routePaths.hseEmployee.addAgency);
  const onDateFiltersChange = (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) => {
    dispatch(setAgenciesOverviewDateRange(dateRangeWithSelectedFilter));
  };

  const AgenciesOverview = () => (
    <div className={styles.main}>
      <div className={styles.container}>
        <Header
          title={t.agencies.Agency}
          onButtonClick={handleOnClick}
          buttonText={t.agencies['Create agency']}
          showTitleOnMobile={false}
        />

        <Box mb={3} className={styles.box}>
          <DateFilterInPast
            onDateFiltersChange={onDateFiltersChange}
            selectedDateFrom={agenciesDateRange?.startDate}
            selectedDateTo={agenciesDateRange?.endDate}
            selectedFilter={agenciesDateRange?.dateFilter}
            shouldIncludeToday
          />
        </Box>
        <div className={styles.agencies_overview}>
          <AgenciesTable />
        </div>
      </div>
    </div>
  );

  return <PageContainer pageToRender={AgenciesOverview()} backgroundColor={theme.whiteLilac} />;
};

export default AgenciesOverviewPage;
